/**
 * Component Name: Dashboard Layout
 * Description: Renders Dashboard Layout
 * Param:
 *      graphComponent
 *      invoiceComponent
 *      billComponent
 *      orgData
 *      onOrgChange
 * Return: View
 * Author: Frances
 * Last Update By: Shirwyn
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid } from '@material-ui/core';
//styles
import { DashboardPageStyles, CommonStyles } from '../../assets/styles';
import { VIEW_INVOICES } from '../../helpers';

const DashboardLayout = ({
  graphComponent,
  invoiceComponent,
  billComponent,
  loadingComponent,
  suggestComponent,
  permissions,
}) => {
  const classes = DashboardPageStyles();
  const commonClasses = CommonStyles();
  return (
    <Grid container spacing={2}>
      <Grid container item className={classes.select}>
        {suggestComponent}
      </Grid>
      <Grid container item spacing={2} className={classes.container}>
        <Grid item md xs={12} className={classes.graphOrder} id="graphOrder">
          <Paper
            className={`${classes.card} ${classes.cardHeight}`}
            id="graphOrderPaper"
          >
            {graphComponent}
          </Paper>
        </Grid>
          <Grid item md xs={12} className={classes.billOrder} id="billOrder">
            <Paper
              className={`${classes.card} ${classes.cardHeight}`}
              id="billOrderPaper"
            >
              {billComponent}
              {loadingComponent}
            </Paper>
          </Grid>
      </Grid>
      {permissions.includes(VIEW_INVOICES) ? (
        <Grid container item xs={12} id="invoiceOrder">
          <Paper className={`${classes.card} ${commonClasses.fluid}`}>
            {invoiceComponent}
          </Paper>
        </Grid>
      ) : null}
    </Grid>
  );
};
//Define the type of props used by loader
DashboardLayout.propTypes = {
  graphComponent: PropTypes.any,
  invoiceComponent: PropTypes.any,
  billComponent: PropTypes.any,
  loadingComponent: PropTypes.bool,
  suggestComponent: PropTypes.any,
};

export { DashboardLayout };
