/**
 * Component Name: ContentView
 * Description: Renders ContentView component
 * Param: AccessToken,
          oldPassword,
          newPassword,
          confirmPassword,
          username,
          viewSideDrawer,
          viewDropDown,
          closeDrawer,
          isViewSideDrawer,
          isViewDropDown,
          isCloseDropDown,
          onUserLogout,
          permissions,
           onInputChangePassword,
          submitChangePassword,
          isChangePassword,
          hasError,
          loaderColor,
          regExp,
          isLoading,
          forceRefreshToken
 * Return: Component
 * Author: Jeremiah
 * Last Update By: RJ
 */

import React, { useState, useEffect } from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { Drawer, Hidden, AppBar, Toolbar, IconButton } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import {
  SideMenu,
  Header,
  ChangePasswordSideDrawer,
  ErrorBoundary,
} from '../../components';
import jsonwebtoken from 'jsonwebtoken';
import DashboardPage from '../../containers/DashboardPage';
import CampaignPage from '../../containers/CampaignPage';
import BillingPage from '../../containers/BillingPage';
import OrganizationPage from '../../containers/OrganizationPage';
import UserPage from '../../containers/UserPage';
import SgmPage from '../../containers/SgmPage';
import { ContentViewStyles } from '../../assets/styles';
import {
  VIEW_CAMPAIGNS,
  VIEW_ORGANIZATIONS,
  VIEW_INVOICES,
  VIEW_USERS,
  VIEW_SGM,
} from '../../helpers';

const Content = ({
  AccessToken,
  oldPassword,
  newPassword,
  confirmPassword,
  username,
  currentUserRole,
  viewSideDrawer,
  viewDropDown,
  closeDrawer,
  isViewSideDrawer,
  isViewDropDown,
  isCloseDropDown,
  onUserLogout,
  permissions,
  onInputChangePassword,
  submitChangePassword,
  isChangePassword,
  hasError,
  loaderColor,
  regExp,
  isLoading,
  forceRefreshToken,
  authStatus,
}) => {
  const classes = ContentViewStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [seconds, setSeconds] = useState(1800);
  const [isActive, setIsActive] = useState(true);
  // Gets the expired parsed date from the decoded AccessToken
  const decodedToken = jsonwebtoken.decode(AccessToken);
  // Calculates the parsed expired date and the parsed date today
  const expired =
    decodedToken?.exp -
    parseInt(Date.parse(new Date()).toString().slice(0, 10));

  /**
   * Function Name: reset
   * Description: Refreshes the token after 30 mins.
   * Param:
   * Return: void
   * Author: Raymart
   * Last Update By: Raymart
   */
  function reset() {
    setSeconds(1800);
    setIsActive(true);
  }

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
        if (seconds === 0) {
          forceRefreshToken();
          reset();
        }
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds, forceRefreshToken]);

  useEffect(() => {
    if (expired <= 0) {
      onUserLogout({ expired: true });
    }
  }, [expired, onUserLogout]);

  /**
   * Function Name: handleDrawerToggle
   * Description: handler for side drawer
   * Param: username, onUserLogout
   * Return: void
   * Author: Jeremiah
   * Last Update By: Jeremiah
   */

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar} id="header-container">
        <Toolbar id="header-sub-container">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <Menu />
          </IconButton>
          <Header
            username={username}
            currentUserRole={currentUserRole}
            onLogout={(e) => {
              onUserLogout({ event: e });
            }}
            isViewSideDrawer={isViewSideDrawer}
            isViewDropDown={isViewDropDown}
            isCloseDropDown={isCloseDropDown}
            viewDropDown={viewDropDown}
            id="username"
          />
        </Toolbar>
      </AppBar>
      <nav
        className={classes.drawer}
        aria-label="mailbox folders"
        id="side-menu-container"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <SideMenu permission={permissions} />
          </Drawer>
        </Hidden>
        `
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <SideMenu permission={permissions} />
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <ErrorBoundary>
          <Switch>
            <Route exact path="/dashboard" component={DashboardPage} />
            {permissions.includes(VIEW_CAMPAIGNS) ? (
              <Route exact path="/campaigns" component={CampaignPage} />
            ) : (
              <Redirect exact from="/campaigns" to="/dashboard" />
            )}
            {permissions.includes(VIEW_INVOICES) ? (
              <Route exact path="/billing" component={BillingPage} />
            ) : (
              <Redirect exact from="/billing" to="/dashboard" />
            )}
            {permissions.includes(VIEW_ORGANIZATIONS) ? (
              <Route exact path="/organizations" component={OrganizationPage} />
            ) : (
              <Redirect exact from="/organizations" to="/dashboard" />
            )}
            {permissions.includes(VIEW_USERS) ? (
              <Route exact path="/users" component={UserPage} />
            ) : (
              <Redirect exact from="/users" to="/dashboard" />
            )}
            {permissions.includes(VIEW_SGM) ? (
              <Route exact path="/sgm" component={SgmPage} />
            ) : (
              <Redirect exact from="/sgm" to="/dashboard" />
            )}
            <Redirect exact from="/login" to="/dashboard" />
            <Redirect exact from="/" to="/login" />
          </Switch>
        </ErrorBoundary>
        {viewSideDrawer === true ? (
          <ChangePasswordSideDrawer
            open={viewSideDrawer}
            authStatus={authStatus}
            closeDrawer={closeDrawer}
            onInputChangePassword={onInputChangePassword}
            oldPassword={oldPassword}
            newPassword={newPassword}
            confirmPassword={confirmPassword}
            submitChangePassword={submitChangePassword}
            isChangePassword={isChangePassword}
            hasError={hasError}
            loaderColor={loaderColor}
            regExp={regExp}
            isLoading={isLoading}
          />
        ) : null}
      </main>
    </div>
  );
};

const ContentView = withRouter(Content);
export { ContentView };
