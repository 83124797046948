import axios from 'axios';
import {
  INVOICE_READ_SUCCESS,
  INVOICE_UNBILLED_TOTAL,
  INVOICE_READ_LIST_SUCCESS,
  VIEW_INVOICE_SUCCESS,
  GET_OUTSTANDING_BALANCE_SUCCESS,
  GET_OUTSTANDING_BALANCE_ERROR,
  INVOICE_READ_LIST_ERROR,
  ROOT_URL,
  INVOICE_READ_PREV_BILL
} from './types';
import { getHeaders } from './AuthActions';
import { errorHandler } from '../helpers';

const INVOICES_API = `${ROOT_URL}invoices-api`;

/**
 * Action Name: readInvoices
 * Description:
 *      Retrieves an arrray of invoices from a specific organization id
 * Param: organization id
 * Return: Array of invoices
 * Author: Gian
 * Last Update By: RJ
 */
export const readInvoices = (organization_id, page = 1, size= 12, isPrevMonth = false) => async (dispatch) => {
  let params = {
    organization_id,
    size,
    page,
    ordering: '-created_timestamp'
  }

  // this is for getting the bill of the previous month
  if(isPrevMonth){
    const date = new Date();
    // get the current month and year
    // Jan-Dec is 0-11
    const month = date.getMonth();
    const year = date.getFullYear();

    // for the backend side the equivalent of Jan-Dec is 1-12
    params = {
      organization_id,
      size,
      page,
      ordering: '-created_timestamp',
      // if the current month is January go back to the previous year
      statement_year: month === 0 ? year - 1 : year,
      // if the current month is January the month passed should be Dec
      statement_month: month === 0 ? 12: month
    }
  }

  const config = {
    headers: (await getHeaders()).headers,
    params
  };
  try {
    const response = await axios.get(`${INVOICES_API}/invoices/`, config);
    if (response.status === 200) {
      if(isPrevMonth){
        dispatch({
          type: INVOICE_READ_PREV_BILL,
          payload: response.data,
        });
      } else {
        dispatch({
          type: INVOICE_READ_SUCCESS,
          payload: response.data,
        });
      }

    }
  } catch (e) {
    dispatch(errorHandler('Invoice', e, 'READ_INVOICES'));
  }
};

/**
 * Action Name: readInvoiceList
 * Description:
 *      Retrieves the all list of invoices
 * Param: orgId, limit
 * Return: invoice list
 * Author: Jeremiah
 * Last Update By: RJ
 */

export const readInvoiceList = (organization_id, size) => async (dispatch) => {
  const config = {
    headers: (await getHeaders()).headers,
    params: {
      organization_id,
      size,
      ordering: '-created_timestamp'
    },
  };
  try {
    const response = await axios.get(`${INVOICES_API}/invoices/`, config);
    dispatch({
      type: INVOICE_READ_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    if (e.response?.status === 500) {
      dispatch({
        type: INVOICE_READ_LIST_ERROR,
        payload: {
          status: e.response.status,
          error: "Can't retrieve all invoice",
        },
      });
    } else {
      dispatch(errorHandler('Invoice', e, 'READ_INVOICE_LIST'));
    }
  }
};

/**
 * Action Name: invoiceUnbilled
 * Description:
 *      Retrieves a total of unbilled invoices of a specific campaign
 * Param: organization id, token
 * Return: total unbilled invoice of a specific campaign
 * Author: Gian
 * Last Update By: RJ
 */
export const invoiceUnbilled = (organization_id) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${INVOICES_API}/organizations/${organization_id}/invoices/unbilled/`,
      await getHeaders()
    );
    dispatch({
      type: INVOICE_UNBILLED_TOTAL,
      payload: response.data.unbilledTotal,
    });
  } catch (e) {
    dispatch(errorHandler('Invoice', e, 'COMPUTE_INVOICE_UNBILLED'));
  }
};

/**
 * Action Name: viewInvoice
 * Description: Returns the url for viewing the invoice.
 * Param: organization_id, invoice_id
 * Return: url
 * Author: Everard
 * Last Update By: RJ
 */
export const viewInvoice =
  (organization_id, invoice_id) => async (dispatch) => {
    try {
      const response = await axios.get(
        `${INVOICES_API}/organizations/${organization_id}/invoices/${invoice_id}/url`,
        await getHeaders()
      );
      if (response.status === 200) {
        dispatch({
          type: VIEW_INVOICE_SUCCESS,
          payload: response.data,
        });
      }
    } catch (e) {
      dispatch(errorHandler('Invoice', e, 'VIEW_INVOICE'));
    }
  };

/**
 * Action Name: getOutstandingBalance
 * Description: Retreive the outstanding balance from xero.
 * Param: organization_id
 * Return: url
 * Author: Everard
 * Last Update By: RJ
 */
export const getOutstandingBalance = (organization_id) => async (dispatch) => {
  const config = {
    headers: (await getHeaders()).headers,
    params: {
      organization_id,
    },
  };
  try {
    const response = await axios.get(`${INVOICES_API}/balances/`, config);
    if (response.status === 200) {
      dispatch({
        type: GET_OUTSTANDING_BALANCE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (e) {
    if (e.response?.status === 500) {
      dispatch({
        type: GET_OUTSTANDING_BALANCE_ERROR,
        payload: {
          error: "Can't retrieve outstanding balance",
          status: e.response.status,
        },
      });
    } else {
      dispatch(errorHandler('Invoice', e, 'GET_OUTSTANDING_BALANCE'));
    }
  }
};
