/**
 * Component Name: UserView
 * Description: Renders UserView component
 * Param: void
 * Return: UserView component
 * Author: Shirwyn
 * Last Update By: Everard
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, FormHelperText } from '@material-ui/core';
import { convertTimestamp } from '../../helpers';
import { UserViewStyles } from './UserViewStyles';

const UserView = ({
  email,
  createdBy,
  dateCreated,
  firstName,
  lastName,
  middleName,
  role,
  active,
  error
}) => {
  const classes = UserViewStyles();
  let roles = []
  role.map(role => roles.push(role.label))

  return (
    <Grid container direction='column' className={classes.container}>
      {error !== '' ? <FormHelperText error id='user-view-error'>
        {
          error.status === 422 && error.errorCodes.includes('INACTIVE_ORGANIZATION') &&
              'Can\'t delete user. Organization is inactive.'
        }
        {
          error.status === 403 && error.errorCodes.includes('UNAUTHORIZED_ACTION') &&
             'You are not authorized to delete this user.'
        }
      </FormHelperText> : null}
      <Grid item xs className={classes.items}>
        <Typography
          variant='body1'
          className={`${classes.label} ${classes.labelTitle}`}
          id='first-name-label'
        >
          First Name
        </Typography>
        <Typography
          variant='body1'
          className={classes.label}
          id='first-name-data'
        >
          {firstName}
        </Typography>
      </Grid>
      <Grid item xs className={classes.items}>
        <Typography
          variant='body1'
          className={`${classes.label} ${classes.labelTitle}`}
          id='last-name-label'
        >
          Last Name
        </Typography>
        <Typography
          variant='body1'
          className={classes.label}
          id='last-name-data'
        >
          {lastName}
        </Typography>
      </Grid>
      <Grid item xs className={classes.items}>
        <Typography
          variant='body1'
          className={`${classes.label} ${classes.labelTitle}`}
          id='middle_name-label'
        >
          Middle Name
        </Typography>
        <Typography
          variant='body1'
          className={classes.label}
          id='middle_name-data'
        >
          {middleName}
        </Typography>
      </Grid>
      <Grid item xs className={classes.items}>
        <Typography
          variant='body1'
          className={`${classes.label} ${classes.labelTitle}`}
          id='email-label'
        >
          Email
        </Typography>
        <Typography variant='body1' className={classes.label} id='email-data'>
          {email}
        </Typography>
      </Grid>
      <Grid item xs className={classes.items}>
        <Typography
          variant='body1'
          className={`${classes.label} ${classes.labelTitle}`}
          id='role-label'
        >
          Role
        </Typography>
        <Typography variant='body1' className={classes.label} id='role-data'>
          {roles.join(', ')}
        </Typography>
      </Grid>
      <Grid item xs className={classes.items}>
        <Typography
          variant='body1'
          className={`${classes.label} ${classes.labelTitle}`}
          id='createdby-label'
        >
          Created By
        </Typography>
        <Typography
          variant='body1'
          className={classes.label}
          id='createdby-data'
        >
          {createdBy}
        </Typography>
      </Grid>
      <Grid item xs className={classes.items}>
        <Typography
          variant='body1'
          className={`${classes.label} ${classes.labelTitle}`}
          id='date-created-label'
        >
          Date Created
        </Typography>
        <Typography
          variant='body1'
          className={classes.label}
          id='date-created-data'
        >
          {convertTimestamp(dateCreated)}
        </Typography>
      </Grid>
      <Grid item xs className={classes.items}>
        <Typography
          variant='body1'
          className={`${classes.label} ${classes.labelTitle}`}
          id='active-label'
        >
          Status
        </Typography>
        <Typography
          variant='body1'
          className={active === true ? classes.active : classes.inactive}
          id='active-data'
        >
          {active ? 'Active' : 'Inactive'}
        </Typography>
      </Grid>
    </Grid>
  );
};
//Define UserView proptypes
UserView.propTypes = {
  email: PropTypes.string,
  created_by: PropTypes.string,
  dateCreated: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  middleName: PropTypes.string,
  role: PropTypes.array,
  active: PropTypes.bool,
  error: PropTypes.string,
};

export { UserView };
