import {
    USER_READ_SUCCESS,
    USER_VIEW_SUCCESS,
    USER_CREATE_SUCCESS,
    USER_UPDATE_SUCCESS,
    USER_DELETE_SUCCESS,
    USER_DELETE_ERROR,
    USER_ERROR, USER_NOT_FOUND,
    SYSTEM_ERROR
} from '../actions/types';

const INITIAL_STATE = {
    userInfo: {
        id: '',
        email: '',
        firstName: '',
        lastName: '',
        middleName: '',
        organizationPk: '',
        roles: [],
        createdBy: '',
        createdTimestamp: '',
        active: ''
    },
    users: [],
    totalCount: 0,
    hasNextPage: false,
    error: '',
    status: '',
    error_code: '',
    noUserFound: false,
    system_error: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case USER_READ_SUCCESS:
            return {
                ...state,
                users: action.payload.results,
                totalCount: action.payload.count,
                hasNextPage: action.payload.next !== null,
                noUserFound: false,
                error: '',
                system_error: false
            };
        case USER_VIEW_SUCCESS:
            return {
                ...state,
                userInfo: action.payload
            };
        case USER_CREATE_SUCCESS:
            return {
                ...state,
                users: [{
                    ...state.users,
                    ...action.payload
                }],
                error: '',
                system_error: false,
                status: '',
            };
        case USER_UPDATE_SUCCESS:
            return {
                ...state,
                userInfo: action.payload,
                users: state.users.map(user =>
                    user.id === action.payload.id ? action.payload : user
                ),
                error: '',
                system_error: false
            };
        case USER_DELETE_SUCCESS:
            return {
                ...state,
                users: state.users.filter(
                    user => user.id !== action.payload
                ),
                totalCount: state.totalCount - 1,
                error: '',
                system_error: false,
                status: ''
            };
        case USER_DELETE_ERROR:
        case USER_ERROR:
            return {
                ...state,
                error: action.payload,
                status: action.payload.status,
                error_code: action.payload.errorCodes
            };
        case USER_NOT_FOUND:
            return {
                noUserFound: true
            };
        case SYSTEM_ERROR:
            return {
                ...state,
                error: action.payload.error,
                status: action.payload.status,
                system_error: true
            };
        default:
            return state;
    }
}
