import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
//Middleware
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from './store';
import { Loader } from './components';

//styles
import { ThemeProvider } from '@material-ui/styles';
import { CommonOverridesStyles } from '../src/assets/styles';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate
      loading={<Loader size={80} color={'#66ccff'} />}
      persistor={persistor}
    >
      <BrowserRouter>
        <ThemeProvider theme={CommonOverridesStyles}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
serviceWorker.unregister();
