/**
 * Class Name: TopUpModal
 * Description: TopUpModal component.
 * Param: N/A
 * Return: TopUpModal Component
 * Author: Ethyl
 * Last Update: Ethyl
 */

import React from "react";
import {
  Dialog,
  IconButton,
  Grid,
  Typography,
  Button,
  TextField,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ThemeProvider } from "@material-ui/styles";
import { TopUpModalStyles } from "./TopUpModalStyles";
import TopUpModalStylesOverrides from "./TopUpModalStylesOverrides";

const TopUpModal = ({
  isOpen,
  onClose,
  onTopUp,
  topUpAmount,
  handleTopUpAmount,
  hasError,
  selectedItem,
    load
}) => {
  const classes = TopUpModalStyles();
  const minValue = -load;
  const inputProps = {
    step: 0.01,
    min: minValue,
  };

  const countDecimals = value => {
    if (Math.floor(value) === value) return 0;
    return value.toString().split('.')[1]?.length || 0;
  };

  return (
    <Dialog
      className={classes.root}
      open={isOpen}
      maxWidth="xs"
      id="top-up-modal"
    >
      <Grid container direction="row" className={classes.container}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Typography
            variant="h6"
            className={classes.dialogTitle}
            id="top-up-modal-title"
          >
            Top Up
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            className={classes.closeButton}
            onClick={onClose}
            id="top-up-modal-close-button"
          >
            <Close />
          </IconButton>
        </Grid>
      </Grid>

      <Typography variant="body1" className={classes.bodyText}>
        Please enter the amount you want to top up to{" "}
        <span className={classes.orgName}>{selectedItem}</span>.
      </Typography>

      <InputLabel className={classes.inputLabel}>Amount</InputLabel>

      <ThemeProvider theme={TopUpModalStylesOverrides}>
        <div className={classes.textFieldContainer}>
          <TextField
            variant="outlined"
            className={classes.input}
            id="top-up-input"
            name="topUpAmount"
            onChange={handleTopUpAmount}
            value={topUpAmount}
            placeholder="0.00"
            autoFocus
            type="number"
            inputProps={inputProps}
            error={
              (topUpAmount === "" && hasError) ||
              (topUpAmount < -load && topUpAmount !== "" && hasError) ||
              (countDecimals(topUpAmount) > 2)
                ? true
                : false
            }
          />

          {topUpAmount === "" && hasError ? (
            <FormHelperText theme={TopUpModalStylesOverrides} error>
              This field is required
            </FormHelperText>
          ) : null}
          {topUpAmount < -load && topUpAmount !== "" && hasError ? (
            <FormHelperText theme={TopUpModalStylesOverrides} error>
              Amount should not be less than the current load
            </FormHelperText>
          ) : null}
          {countDecimals(topUpAmount) > 2 ? (
              <FormHelperText error id="topup-decimal-error">Amount must not be more than 2 decimal places</FormHelperText>
          ) : null}
        </div>
      </ThemeProvider>

      <Grid container direction="row" className={classes.buttonContainer}>
        <Grid item xs={6}>
          <Button
            onClick={() => onTopUp(topUpAmount)}
            variant="contained"
            color="primary"
            className={classes.TopUpButton}
            id="top-up-modal-button"
          >
            Top Up
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            className={classes.cancelButton}
            id="top-up-modal-cancel-button"
            onClick={onClose}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default TopUpModal;
