import axios from 'axios';
import {
  USER_READ_SUCCESS,
  USER_VIEW_SUCCESS,
  USER_CREATE_SUCCESS,
  USER_UPDATE_SUCCESS,
  USER_DELETE_SUCCESS,
  USER_SERVER_ADDRESS, USER_ERROR,
} from './types';
import { errorHandler } from '../helpers';
import { getHeaders } from './AuthActions';

const ROOT_URL = `${USER_SERVER_ADDRESS}/organizations`;

/**
 * Action Name: readUsers
 * Description:
 *      retrieve all users from a specific organization
 * Param: org_id, active, page_size, page_number
 * Return: array of users
 * Author: Shirwyn
 * Last Update By: Raymart
 */
export const readUsers = (
    organizationId = '',
    page = 1,
    active = undefined,
    limit=10
) => async dispatch => {
  try {
    let params = {
      page,
      limit
    };
    if (active!==undefined){
      params = {
        page,
        active,
        limit
      }
    }
    const headers = (await getHeaders()).headers;
    let config = {
      headers,
      params
    }
    const response = await axios.get(`${ROOT_URL}/${organizationId}/users/`, config);
    dispatch({
      type: USER_READ_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    console.log('error', error);
    dispatch(errorHandler('User', error));
  }
};

/**
 * Action Name: viewUsers
 * Description:
 *      retrieve specific user
 * Param: org_id, user_id
 * Return: object with user_id,
 *          email,
 *          first_name,
 *          last_name,
 *          middle_name,
 *          organization_id,
 *          roles,
 *          created_by,
 *          created_timestamp,
 *          active
 * Author: Shirwyn
 * Last Update By: Raymart
 */
export const viewUser = (organizationId, userId) => async (dispatch) => {
  try {
    const response = await axios.get(
        `${ROOT_URL}/${organizationId}/users/${userId}/`,
        await getHeaders()
    );
    dispatch({
      type: USER_VIEW_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch(errorHandler('User', e, 'VIEW_USER'));
  }
};

/**
 * Action Name: createUsers
 * Description:
 *      create a new user for an organization
 * Param: org_id, payload
 * Return: new single data of user
 * Author: Shirwyn
 * Last Update By: Nelly
 */
export const createUser = (organizationId, payload) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${ROOT_URL}/${organizationId}/users/`,
      payload,
      await getHeaders()
    );
      dispatch({
        type: USER_CREATE_SUCCESS,
        payload: response.data,
      });
  } catch (e) {
      dispatch( {
        type: USER_ERROR,
        payload: {
          errorCodes: e.response.data.errorCodes,
          status: e.response.status,
        }
    })
  }
};

/**
 * Action Name: updateUsers
 * Description:
 *      updates an specific user
 * Param: org_id, user_id, payload
 * Return: updated user
 * Author: Shirwyn
 * Last Update By: Raymart
 */
export const updateUser = (organizationId, userId, payload) => async (dispatch) => {
  try {
    const response = await axios.put(
      `${ROOT_URL}/${organizationId}/users/${userId}/`,
      payload,
      await getHeaders()
    );
    if (response.status === 200) {
      dispatch({
        type: USER_UPDATE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch({
      type: USER_ERROR,
      payload: {
        errorCodes: e.response.data.errorCodes,
        status: e.response.status,
      },
    })
  }
};

/**
 * Action Name: deleteUsers
 * Description:
 *      deletes a specific user
 * Param: org_id, user_id
 * Return: array without the deleted user
 * Author: Shirwyn
 * Last Update By: Nelly
 */
export const deleteUser = (organizationId, userId) => async (dispatch) => {
  try {
    await axios.delete(
      `${ROOT_URL}/${organizationId}/users/${userId}/account-registry/`,
      await getHeaders()
    );
      dispatch({
        type: USER_DELETE_SUCCESS,
        payload: userId,
      });
  } catch (e) {
    dispatch({
      type: USER_ERROR,
      payload: {
        errorCodes: e.response.data.errorCodes,
        status: e.response.status,
      },
    })
  }
};
