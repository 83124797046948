import axios from 'axios';
import {
  ORGANIZATION_CREATE_SUCCESS,
  ORGANIZATION_DELETE_SUCCESS,
  ORGANIZATION_UPDATE_SUCCESS,
  ORGANIZATION_READ_SUCCESS,
  ORGANIZATION_VIEW_SUCCESS,
  ROOT_URL,
} from './types';
import { convertTimestamp, errorHandler } from '../helpers';
import { getHeaders } from './AuthActions';

const ORGANIZATION_API = `${ROOT_URL}organizations-api/organizations/`;

/**
 * Action Name: readOrganizations
 * Description:
 *      retrieve all organization
 * Param: void
 * Return: array of organizations
 * Author: Shirwyn
 * Last Update By: RJ
 */
export const readOrganizations =
  (active = '', size = 10, page = 1, ordering = 'organization_name') =>
  async (dispatch) => {
    const config = {
      headers: (await getHeaders()).headers,
      params: {
        page,
        active,
        size,
        ordering
      },
    };
    try {
      const response = await axios.get(`${ORGANIZATION_API}`, config);

      const orgData = response.data.results.map(
        ({
          id,
          canHostSuperAdmin,
          organizationName: org_name,
          load,
          paymentMode,
          active,
          smsRate: rate,
          smsRateSgm: rate_sgm,
          createdBy: created_by,
          createdTimestamp: created_timestamp,
        }) => ({
          id,
          canHostSuperAdmin,
          paymentMode,
          load,
          org_name,
          active,
          rate,
          rate_sgm,
          created_by,
          created_timestamp: convertTimestamp(created_timestamp),
        })
      );

      dispatch({
        type: ORGANIZATION_READ_SUCCESS,
        payload: {
          org_list: orgData,
          total_count: response.data.results.length,
          nextPage: response.data.next,
          previousPage: response.data.previous,
        },
      });
    } catch (e) {
      dispatch(errorHandler('Organization', e));
    }
  };

/**
 * Action Name: createOrganizations
 * Description:
 *      creates a new organization
 * Param: payload
 * Return: a new single organization data
 * Author: Shirwyn
 * Last Update By: RJ
 */
export const createOrganization = (payload) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${ORGANIZATION_API}`,
      payload,
      await getHeaders()
    );
    if (response.status === 201) {
      dispatch({
        type: ORGANIZATION_CREATE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch(errorHandler('Organization', e));
  }
};

/**
 * Action Name: updateOrganizations
 * Description:
 *      updates an organizations information
 * Param: id, payload
 * Return: updated organization information
 * Author: Shirwyn
 * Last Update By: RJ
 */
export const updateOrganization = (id, payload) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${ORGANIZATION_API}${id}/`,
      payload,
      await getHeaders()
    );
    if (response.status === 200) {
      dispatch({
        type: ORGANIZATION_UPDATE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch(errorHandler('Organization', e));
  }
};

/**
 * Action Name: deleteOrganizations
 * Description:
 *      deletes a specific organization based on id
 * Param: id
 * Return: array without the deleted organization
 * Author: Shirwyn
 * Last Update By: RJ
 */
export const deleteOrganization = (id) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${ORGANIZATION_API}${id}/`,
      {
        active: false,
      },
      await getHeaders()
    );
    dispatch({
      type: ORGANIZATION_DELETE_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch(errorHandler('Organization', e));
  }
};

/**
 * Action Name: viewOrganizations
 * Description:
 *      retrieve a specific
 * Param: payload
 * Return: object with org_id,
 *          aws_secret,
 *          org_name,
 *          date_created,
 *          created_by,
 *          created_timestamp,
 *          active,
 *          rate
 * Author: Shirwyn
 * Last Update By: RJ
 */
export const viewOrganization = (id) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${ORGANIZATION_API}${id}/`,
      await getHeaders()
    );
    dispatch({
      type: ORGANIZATION_VIEW_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch(errorHandler('Organization', e));
  }
};
