import React from 'react';
import MomentUtils from '@date-io/moment';
import {
  TextField,
  FormControl,
  Button,
  Grid,
  Typography,
  FormHelperText,
  Select,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
  Link,
} from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import { Loader } from '../../components';
import { ThemeProvider } from '@material-ui/styles';
import { ExpandMore } from '@material-ui/icons';
import CampaignFormStyles from './CampaignFormStyles';
import CampaignFormOverrideStyles from './CampaignFormOverrideStyles';
import { VIEW_ORGANIZATIONS } from '../../helpers';
import { pp_blue_color_loader } from '../../assets/styles/types';

const CampaignForm = ({
  campaignName,
  handleCSV,
  onSubmitCSV,
  handleName,
  errorCSVMessage, //CSV Error Message
  errorOrgMessage, //Org Error Message
  errorCampaignMessage, //Campaign Error Message
  errorScheduledDateTimeMessage, // Date/Time Error Message
  onCSVStatus, // CSV Error Status
  onOrgStatus, // Org Error Status
  onCampaignStatus, // Campaign Error Status
  handleOrg,
  orgSelect,
  selectOptions,
  campaignSubmitDisable,
  isScheduledCampaign,
  onClickSendAsScheduledCampaign,
  onClickDoNotCharge,
  scheduledDateTime,
  handleDateTimeChange,
  dateTimePickerFieldError,
  permissions,
  onCancel,
  btn,
  onClose,
  formatInput,
  doNotCharge,
  currentUserRole,
  error_url,
}) => {
  const classes = CampaignFormStyles();
  const upload_label = 'Upload File (*.xls, *.xlsx)';
  return (
    <FormControl className={classes.form} id="campaign-form" onClose={onClose}>
      <Grid container item direction="row" xs={12}>
        <Grid item xs={12} className={classes.gridMargin}>
          <Typography variant="body1" className={classes.label}>
            Campaign Name
          </Typography>

          <TextField
            id="create-campaign-field"
            autoFocus
            type="text"
            variant="outlined"
            value={campaignName}
            name="campaignName"
            onChange={handleName}
            className={classes.textField}
            classes={{ input: classes.drawerInput }}
            error={
              errorCampaignMessage !== '' &&
              onCampaignStatus !== '' &&
              campaignName === ''
            }
            onBlur={formatInput}
          />
          {errorCampaignMessage !== '' &&
          onCampaignStatus !== '' &&
          campaignName === '' ? (
            <FormHelperText id="error-campaign-message" error={true}>
              {errorCampaignMessage}
            </FormHelperText>
          ) : null}
        </Grid>
        {permissions.includes(VIEW_ORGANIZATIONS) ? (
          <Grid item xs={12} className={classes.gridMargin}>
            <Typography variant="body1" className={classes.label}>
              Organization
            </Typography>
            <ThemeProvider theme={CampaignFormOverrideStyles}>
              <Select
                id="organization-field"
                className={classes.textField}
                IconComponent={ExpandMore}
                name="selectedOrgId"
                value={orgSelect}
                onChange={handleOrg}
                native
                required
                error={
                  errorOrgMessage !== '' &&
                  onOrgStatus !== '' &&
                  orgSelect === ''
                }
                input={
                  <OutlinedInput
                    labelWidth={0}
                    name="status"
                    className="organization-selection"
                    id="organization-selection"
                  />
                }
              >
                {selectOptions.map(({ active, org_name, org_id }) => {
                  if (active) {
                    return (
                      <option key={org_id} value={org_id}>
                        {org_name}
                      </option>
                    );
                  } else {
                    return null;
                  }
                })}
              </Select>
              {onOrgStatus !== '' && orgSelect === '' ? (
                <FormHelperText id="org-error-message" error={true}>
                  {errorOrgMessage}
                </FormHelperText>
              ) : null}
            </ThemeProvider>
          </Grid>
        ) : null}

        <Grid item xs={12} className={classes.gridMargin}>
          <Typography variant="body1" className={classes.label}>
            {upload_label}
          </Typography>
          <ThemeProvider theme={CampaignFormOverrideStyles}>
            <TextField
              id="upload-file-field"
              type="file"
              variant="outlined"
              name="file"
              placeholder="CSV file"
              className={classes.textField}
              onChange={handleCSV}
              error={
                errorCSVMessage !== '' &&
                onCSVStatus !== '' &&
                handleCSV !== null
              }
            />
          </ThemeProvider>
        </Grid>
        <div className={classes.errorDivBtn}>
          {errorCSVMessage !== '' &&
          onCSVStatus !== '' &&
          handleCSV !== null ? (
            <FormHelperText
              id="error-cvs-message"
              error={true}
              className={classes.errorCsv}
            >
              {errorCSVMessage}.{' '}
            </FormHelperText>
          ) : null}
          {errorCSVMessage === 'File contains invalid entries' ? (
            <Link
              id="validated-file-link"
              className={classes.link}
              href={error_url}
            >
              <span id="download-validated-file" className={classes.span}>
                {' '}
                Download validated file
              </span>
            </Link>
          ) : null}
        </div>
        <Grid item xs={12} className={classes.gridMarginBtn}>
          <Grid container>
            <Grid item xs={12}>
              <Button
                id="download-template-button"
                className={classes.templateLink}
                variant="contained"
                href="https://sms-app-assets.s3.ap-southeast-1.amazonaws.com/campaign_template.xlsx"
              >
                <GetAppRoundedIcon />
                Download Template
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.gridMargin}>
          <Grid container>
            <Grid item xs={12}>
              {currentUserRole === 'super_admin' ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      id="do-not-charge-checkbox"
                      checked={doNotCharge}
                      onChange={onClickDoNotCharge}
                      value="doNotCharge"
                      color="primary"
                    />
                  }
                  label="Do not charge?"
                  className={classes.label}
                />
              ) : null}

              <FormControlLabel
                control={
                  <Checkbox
                    id="scheduled-campaign"
                    checked={isScheduledCampaign}
                    onChange={onClickSendAsScheduledCampaign}
                    value="scheduled"
                    color="primary"
                  />
                }
                label="Scheduled Campaign"
                className={classes.label}
              />
            </Grid>
            {isScheduledCampaign ? (
              <Grid item xs={12} className={classes.gridMargin}>
                <ThemeProvider theme={CampaignFormOverrideStyles}>
                  <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    styles={{ color: '#fff' }}
                  >
                    <KeyboardDateTimePicker
                      id="time-picker-keyboard"
                      variant="inline"
                      inputVariant="outlined"
                      ampm={true}
                      value={scheduledDateTime}
                      onChange={handleDateTimeChange}
                      disablePast
                      format="llll"
                      onError={(error) => {
                        if (
                          error !== '' &&
                          error !== errorScheduledDateTimeMessage
                        ) {
                          dateTimePickerFieldError(error);
                        }
                      }}
                      error={errorScheduledDateTimeMessage !== ''}
                      helperText={null}
                      className={classes.textField}
                    />
                  </MuiPickersUtilsProvider>
                  {errorScheduledDateTimeMessage !== '' ? (
                    <FormHelperText error={true}>
                      {errorScheduledDateTimeMessage}
                    </FormHelperText>
                  ) : null}
                </ThemeProvider>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <div className={classes.divBtn}>
          <Button
            id="campaign-form-button"
            onClick={onSubmitCSV}
            variant="contained"
            className={classes.button}
            disabled={campaignSubmitDisable}
          >
            {campaignSubmitDisable ? (
              <div id="button-loader" className={classes.buttonLoader}>
                <Loader
                  size={60}
                  adjustSmall={window.screen.width <= 520}
                  color={pp_blue_color_loader}
                />
              </div>
            ) : (
              'Submit'
            )}
          </Button>
          <Button
            id="on-cancel-button"
            variant="contained"
            className={classes.CancelBtn}
            onClick={onCancel}
          >
            {btn}
          </Button>
        </div>
      </Grid>
    </FormControl>
  );
};

export { CampaignForm };
