import {
  SGM_READ_SUCCESS,
  SGM_ERROR,
  SGM_FILTER_SUCCESS,
  UNAUTHORIZED_ERROR,
} from '../actions/types';

const INITIAL_STATE = {
  sgmData: {
    completedTimestamp: '',
    createdTimestamp: '',
    failureReason: null,
    id: '',
    organization_id: '',
    smsRate: '',
    status: '',
    mobileNumber: '',
  },
  filteredMessages: [],
  totalCount: 0,
  message_id: '',
  created_timestamp: '',
  error: '',
  hasNextPage: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SGM_FILTER_SUCCESS:
      return {
        ...state,
        filteredMessages: action.payload.sgmList,
        totalCount: action.payload.count,
        hasNextPage: action.payload.next !== null,
      };
    case SGM_READ_SUCCESS:
      return {
        ...state,
        sgmData: action.payload,
      };
    case SGM_ERROR:
      return {
        ...state,
        error: action.payload.error,
        status: 'unable_to_retrieve_sgm',
      };
    case UNAUTHORIZED_ERROR:
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
      };
    default:
      return state;
  }
}
