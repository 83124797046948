/**
 * Style Name: TopUpModalStyles
 * Description: Styles for User form Component
 * Param: void
 * Return: class styles
 * Author: Ethyl
 * Last Update By: Ethyl
 */

import { makeStyles } from "@material-ui/core/styles";
import { black_color } from "../../assets/styles/types";

const TopUpModalStyles = makeStyles(() => ({
  root: {
    borderRadius: "10px",
  },
  container: {
    justifyContent: "center",
    overflowX: "hidden",
    paddingTop: "0.5rem",
  },
  dialogTitle: {
    color: black_color,
    padding: "1rem",
    textAlign: "center",
    fontWeight: "bold",
    opacity: "0.6",
  },
  closeButton: {
    padding: "0!important",
    marginTop: "0.5rem",
  },
  bodyText: {
    color: black_color,
    paddingBottom: "1.5rem",
    margin: "0rem 3.5rem",
    opacity: "0.6",
    maxWidth: "20rem",
    fontSize: "16px",
    textAlign: "center",
    alignSelf: "center",
    lineHeight: "1.25rem",
  },
  inputLabel: {
    color: black_color,
    opacity: "0.5",
    marginLeft: "2.25rem",
    marginBottom: "0.25rem",
  },
  textFieldContainer: {
    marginLeft: "2rem",
    marginTop: "0.5rem",
  },
  input: {
    textTransform: "capitalize",
    color: "#fff",
    whiteSpace: "nowrap",
    width: "93%",
  },
  buttonContainer: {
    margin: "2rem 0",
    textAlign: "center",
  },
  TopUpButton: {
    textTransform: "capitalize",
    backgroundColor: "#2baaed",
    color: "#fff",
    borderRadius: "0!important",
    whiteSpace: "nowrap",
    height: "3rem",
    width: window.screen.width <= 350 ? "25vw" : "9.45rem",
    "&:hover": {
      backgroundColor: "#2baaed",
    },
    "&:disabled:hover": {
      backgroundColor: "#2baaed",
    },
    "&:disabled": {
      backgroundColor: "#2baaed",
    },
  },
  cancelButton: {
    textTransform: "capitalize",
    color: black_color,
    opacity: "0.5",
    borderRadius: "0!important",
    whiteSpace: "nowrap",
    backgroundColor: "#c0c0c0",
    height: "3rem",
    width: window.screen.width <= 350 ? "25vw" : "9.45rem",
    "&:hover": {
      backgroundColor: "#c0c0c0",
    },
  },
  orgName: {
    fontWeight: "bold",
  },
}));

export { TopUpModalStyles };
