import { createTheme } from '@material-ui/core/styles';
import { pp_blue_color } from '../../assets/styles/types';

const PaginationStylesOverride = createTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiOutlinedInput: {
      // Name of the rule
      input: {
        // Some CSS
        padding: '0.58em',
      },
    },
  },
  palette: {
    primary: {
      main: pp_blue_color,
    },
  },
});

export default PaginationStylesOverride;
