/**
 * Component Name: ForceChangePassword
 * Description: Displays the force change password form
 * Param: newPass, confirmPass, isSubmitPassword, onSubmitChangePassword, onChangePasswordInput, regExpValidator, isAuthenticating, onSubmitChangePasswordInput, hasError
 * Return: component
 * Author: Jeremiah
 * Last Update By: Jeremiah
 */
import React from 'react';
import {
  Paper,
  InputLabel,
  FormControl,
  FormHelperText,
  Button,
  ListItem,
  List,
} from '@material-ui/core';
import { Route } from 'react-router-dom';
import { FormInput, ForceChangePasswordStyles } from '../../assets/styles';
import { Loader } from '../../components';

const ForceChangePassword = ({
  type,
  loaderColor,
  newPass,
  confirmPass,
  isSubmitPassword,
  onSubmitChangePassword,
  onChangePasswordInput,
  regExpValidator,
  isAuthenticating,
  onSubmitChangePasswordInput,
  hasError,
}) => {
  const classes = ForceChangePasswordStyles();
  return (
    <Paper className={classes.formContainer} id="forgot-page-container">
      <img
        src={require('../../assets/sms-full.png')}
        alt="pearlpay"
        className={classes.image}
      />
      <form
        onSubmit={onSubmitChangePassword}
        onKeyDown={onSubmitChangePasswordInput}
        className={classes.form}
      >
        <div>
          <FormControl className={classes.margin} id="newPass-input-container">
            <InputLabel
              shrink
              htmlFor="bootstrap-input"
              className={classes.inputLabel}
            >
              New Password
            </InputLabel>
            <FormInput
              id="bootstrap-new-password-input"
              type="password"
              value={newPass || ''}
              onChange={onChangePasswordInput}
              onClick={onSubmitChangePasswordInput}
              name="newPass"
            />
          </FormControl>
        </div>
        {isSubmitPassword && (newPass === null || newPass === '') ? (
          <FormHelperText error className={classes.errorMessage}>
            New password is required
          </FormHelperText>
        ) : null}
        <div>
          <FormControl
            className={classes.margin}
            id="confirmPass-input-container"
          >
            <InputLabel
              shrink
              htmlFor="bootstrap-input"
              className={classes.inputLabel}
            >
              Confirm Password
            </InputLabel>
            <FormInput
              id="bootstrap-confirm-password-input"
              type="password"
              value={confirmPass || ''}
              onChange={onChangePasswordInput}
              onClick={onSubmitChangePasswordInput}
              name="confirmPass"
            />
          </FormControl>
        </div>
        {isSubmitPassword && (confirmPass === null || confirmPass === '') ? (
          <FormHelperText error className={classes.errorMessage}>
            Confirm password is required
          </FormHelperText>
        ) : null}
        {isSubmitPassword &&
        newPass !== confirmPass &&
        newPass &&
        confirmPass ? (
          <FormHelperText error className={classes.errorMessage}>
            Password do not match
          </FormHelperText>
        ) : null}
        {isSubmitPassword && !regExpValidator && newPass && confirmPass ? (
          <FormHelperText error className={classes.errorMessage}>
            Invalid password format
          </FormHelperText>
        ) : null}
        {isSubmitPassword &&
          (newPass.length > 98 || newPass.length < 6) &&
          newPass &&
          confirmPass && (
            <FormHelperText error className={classes.errorMessage}>
              {hasError}
            </FormHelperText>
          )}
        {isSubmitPassword &&
        newPass === confirmPass &&
        !(newPass.length > 98 || newPass.length < 6) &&
        hasError &&
        newPass &&
        confirmPass ? (
          <FormHelperText error className={classes.errorMessage}>
            Change password failed. Please try again.
          </FormHelperText>
        ) : null}

        <div className={classes.gridItem}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            className={classes.inputLabel2}
          >
            Minimum length is 6 characters, maximum is 98.
          </InputLabel>
          <List className={classes.guideLabel} id="guide-container">
            <ListItem className={classes.listItem}>
              - Must contain at least one number.
            </ListItem>
            <ListItem className={classes.listItem}>
              - Must contain at least one special character (e.g. !, @, #, $
              etc.) -- spaces do not count.
            </ListItem>
            <ListItem className={classes.listItem}>
              - Must contain at least one lowercase and one uppercase alphabet.
            </ListItem>
          </List>
        </div>
        <Route
          render={() => (
            <Button
              variant="contained"
              type="submit"
              size="large"
              color="primary"
              className={classes.loginButton}
              onClick={onSubmitChangePassword}
              disabled={isSubmitPassword && regExpValidator && isAuthenticating}
            >
              {isSubmitPassword && isAuthenticating && !hasError ? (
                <div className={classes.loader}>
                  <Loader
                    size={80}
                    adjustSmall={window.screen.width <= 520}
                    color={loaderColor}
                  />
                </div>
              ) : (
                type
              )}
            </Button>
          )}
        />
      </form>
    </Paper>
  );
};

export { ForceChangePassword };
