/**
 * Component Name: Billing Page Layout
 * Description: Renders Billing Page Layout
 * Param:
 *      invoiceComponent
 *      billComponent
 *      permissions
 *      organizationSuggest
 * Return: View
 * Author: Jeremiah
 * Last Update By: Shirwyn
 */
import React from 'react';
import PropTypes from 'prop-types';
import {Paper, Grid} from '@material-ui/core';
//styles
import {BillingPageStyles, BillingCommonStyles} from '../../assets/styles';
import {VIEW_INVOICES, VIEW_ORGANIZATIONS} from "../../helpers";

const BillingPageLayout = ({
invoiceComponent,
billComponent,
permissions,
organizationSuggest,
paginationComponent
}) => {
    const classes = BillingPageStyles();
    const commonClasses = BillingCommonStyles();
    return (
        <Grid container spacing={2}>
            {
                permissions.includes(VIEW_ORGANIZATIONS) ?
                    <Grid container item className={classes.select}>
                        {organizationSuggest}
                    </Grid> : null
            }
            <Grid container item spacing={2}>
                <Grid item md={6} xs={12} className={classes.billOrder}>
                    <Paper className={classes.card}>
                        {billComponent}
                    </Paper>
                </Grid>
            </Grid>
            {
                permissions.includes(VIEW_INVOICES) ?
                    <>
                        <Grid container item>
                            <Grid item md={12} xs={12} className={classes.billOrder}>
                                {paginationComponent}
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} className={classes.gridHeight}>
                            <Paper className={`${classes.card} ${commonClasses.fluid}`}>
                                {invoiceComponent}
                            </Paper>
                        </Grid>
                    </>

                    : null
            }
        </Grid>
    )
};

BillingPageLayout.propTypes = {
    invoiceComponent: PropTypes.any,
    billComponent: PropTypes.any,
    permissions: PropTypes.any,
    organizationSuggest: PropTypes.any
};

export {BillingPageLayout};
