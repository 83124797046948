/**
 * Function Name: thisUserTitle
 * Description: handles the title for the side drawer
 * Param: type, title
 * Return: Typography title
 * Author: Shirwyn
 * Last Update: Shirwyn
 */
import React from 'react';
import PropTypes from 'prop-types';
import { DELETE_USERS, UPDATE_USERS } from '../../helpers';
import {
  Drawer,
  Button,
  Typography,
  Grid,
  IconButton,
  Divider,
  Tooltip,
} from '@material-ui/core';
import { KeyboardBackspace, Create, Delete } from '@material-ui/icons';
import { UserModal } from '../../components';
import { UserSideDrawerStyles } from './UserSideDrawerStyles';

const thisUserTitle = (type, title) => {
  switch (type) {
    case 'view':
      return `View ${title}`;
    case 'edit':
      return `Edit ${title}`;
    case 'create':
      return `Create ${title}`;
    default:
      return null;
  }
};
/**
 * Class Name: UserSideDrawer
 * Description: UserSideDrawer component.
 * Param: N/A
 * Return: UserSideDrawer Component
 * Author: Shirwyn
 * Last Update: Jeremiah
 */
const UserSideDrawer = ({
  type,
  title,
  loadingModal,
  children,
  drawerState,
  onBack,
  onEdit,
  onDelete,
  isActive,
  showModal,
  handleCloseModal,
  getSelectedItem,
  deleteSelected,
  permissions,
  loaderColor,
  canDelete = true
}) => {
  const classes = UserSideDrawerStyles();

  return (
    <Drawer anchor='right' open={drawerState} onClose={onBack}>
      <div className={classes.drawer}>
        <Button
          onClick={onBack}
          className={classes.button}
          id='User-side-drawer-back-button'
        >
          <KeyboardBackspace />
          Back
        </Button>
        <Grid container className={classes.container}>
          <Grid item className={classes.gridItem}>
            <Typography variant='h5' gutterBottom className={classes.title}>
              {thisUserTitle(type, title)}
            </Typography>
          </Grid>
          {type === 'view' ? (
            <Grid item className={classes.gridItem}>
              {permissions.includes(UPDATE_USERS) ? (
                <Tooltip title='Edit'>
                  <div>
                    <IconButton
                      onClick={onEdit}
                      id='User-side-drawer-edit-button'
                      disabled={!isActive}
                    >
                      <Create />
                    </IconButton>
                  </div>
                </Tooltip>
              ) : null}
              {permissions.includes(DELETE_USERS) && canDelete  ? (
                <Tooltip title='Delete'>
                  <div>
                    <IconButton
                      disabled={!isActive}
                      onClick={onDelete}
                      id='User-side-drawer-delete-button'
                    >
                      <Delete />
                    </IconButton>
                  </div>
                </Tooltip>
              ) : null}
            </Grid>
          ) : null}
        </Grid>
        <UserModal
          isOpen={showModal}
          activePage={title}
          closeModal={handleCloseModal}
          selectedItem={getSelectedItem}
          deleteItem={deleteSelected}
          isLoadingModal={loadingModal}
          loaderColor={loaderColor}
        />
        <Divider className={classes.divider} />
        <div>{children}</div>
      </div>
    </Drawer>
  );
};
//Define OrganisationSideDrawer proptypes
UserSideDrawer.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.object,
  drawerState: PropTypes.bool,
  onBack: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  getSelectedItem: PropTypes.string,
  deleteSelected: PropTypes.func,
  isActive: PropTypes.bool,
  permissions: PropTypes.any,
};
export { UserSideDrawer, thisUserTitle };
