import axios from 'axios';
import {
  CAMPAIGNS_CREATE_SUCCESS,
  CAMPAIGNS_READ_SUCCESS,
  CAMPAIGN_VIEW_SUCCESS,
  CAMPAIGN_CREATE_VALIDATE_SUCCESS,
  CAMPAIGN_ERROR,
  ROOT_URL,
} from './types';
import { convertTimestamp, errorHandler, statusFilter } from '../helpers';
import { getHeaders } from './AuthActions';

const CAMPAIGN_API = `${ROOT_URL}campaigns-api/organizations`;
/**
 * Action Name: readCampaigns
 * Description:
 *      get all campaigns from a specific organization
 * Param: org_id, page_size, page_number
 * Return: array of campaign list
 * Author: Gian
 * Last Update By: RJ
 */
export const readCampaigns =
  (org_id, page_size = 0, page = 1) =>
  async (dispatch) => {
    try {
      let response;
      const config = {
        headers: (await getHeaders()).headers,
        params: {
          page,
          page_size,
        },
      };

      if (page_size > 0) {
        response = await axios.get(
          `${CAMPAIGN_API}/${org_id}/display/`,
          config
        );
      } else {
        response = await axios.get(
          `${CAMPAIGN_API}/${org_id}/display/`,
          await getHeaders()
        );
      }

      const campaignData = response.data.results.map(
        ({
          id,
          organizationId,
          name: campaign_name,
          status,
          completedTimestamp: completed_timestamp,
          createdTimestamp: created_timestamp,
          totalCount,
          successCount,
            failedCount,
          createdBy: created_by
        }) => ({
          id,
          organizationId,
          campaign_name,
          status: statusFilter(status),
          completed_timestamp: completed_timestamp
            ? convertTimestamp(completed_timestamp)
            : '---',
          created_timestamp: convertTimestamp(created_timestamp),
          totalCount,
          successCount,
          created_by,
          failedCount
        })
      );

      dispatch({
        type: CAMPAIGNS_READ_SUCCESS,
        payload: {
          campaigns_list: campaignData,
          total_count: response.data.results.length,
          nextPage: response.data.next,
          previousPage: response.data.previous,
        },
      });
    } catch (e) {
      dispatch(errorHandler('Campaign', e));
    }
  };

/**
 * Action Name: createCampaigns
 * Description:
 *      creates campaign for a specific organization
 * Param: payload, organization_id
 * Return: a new single campaign data
 * Author: Gian
 * Last Update By: RJ
 */
export const createCampaigns =
  (payload, organization_id) => async (dispatch) => {
    try {
      const response = await axios.post(
        `${CAMPAIGN_API}/${organization_id}/create/`,
        payload,
        await getHeaders()
      );
      dispatch({
        type: CAMPAIGNS_CREATE_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      dispatch(errorHandler('Campaign', e));
    }
  };

/**
 * Action Name: viewCampaign
 * Description:
 *      get all campaigns from a specific organization
 * Param: campaign_id, organization_id
 * Return: object with campaign_name.
 *          created_by,
 *          date_created,
 *          date_completed,
 *          status
 * Author: Gian
 * Last Update By: RJ
 */
export const viewCampaign =
  (campaign_id, organization_id) => async (dispatch) => {
    try {
      const response = await axios.get(
        `${CAMPAIGN_API}/${organization_id}/display/${campaign_id}/`,
        await getHeaders()
      );
      dispatch({
        type: CAMPAIGN_VIEW_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      dispatch(errorHandler('Campaign', e));
    }
  };

/**
 * Action Name: fileUploadDetails
 * Description:
 *      Get the details to be able to upload a campaign file directly to AWS S3 and and use the response to upload a file.
 * Param: payload, org id and file - file_type must be xlsx or xls
 * Return: If file is validated.
 * Author: Nelly
 * Last Update By: RJ
 */
export const fileUploadDetails =
  (payload, organization_id, fileUpload) => async (dispatch) => {
    try {
      const response = await axios.post(
        `${CAMPAIGN_API}/${organization_id}/file-upload-details/`,
        payload,
        await getHeaders()
      );
      dispatch({
        type: CAMPAIGN_CREATE_VALIDATE_SUCCESS,
        payload: response.data,
      });

      const urlData = response.data.url;
      let data = response.data.fields;

      let formData = new FormData();

      for (var key in data) {
        formData.append(key, data[key]);
      }
      formData.append('file', fileUpload);
      axios.post(urlData, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (e) {
      dispatch(errorHandler('Campaign', e, 'VALIDATE_CAMPAIGN'));
    }
  };

/**
 * Action Name: validationStatus
 * Description:
 *      Get the status of the validation of a recently uploaded campaign file to AWS S3.
 * Param: organization_id and campaign_id
 * Return: None
 * Author: Nelly
 * Last Update By: RJ
 */
export const validationStatus =
  (organization_id, campaign_id) => async (dispatch) => {
    try {
      const response = await axios.get(
        `${CAMPAIGN_API}/${organization_id}/validations/${campaign_id}/`,
        await getHeaders()
      );

      if (
        response.data.status === 'PENDING' ||
        response.data.status === 'ONGOING'
      ) {
        return response.data;
      } else if (response.data.status === 'FAILED') {
        dispatch({
          type: CAMPAIGN_ERROR,
          payload: response.data,
        });
      } else {
        dispatch({
          type: CAMPAIGNS_CREATE_SUCCESS,
          payload: response.data,
        });
      }
      return response.data;
    } catch (e) {
      dispatch(errorHandler('Campaign', e));
    }
  };
