import axios from 'axios';
import {
  SGM_ERROR,
  SGM_READ_SUCCESS,
  SGM_FILTER_SUCCESS,
  SGM_SERVER_ADDRESS,
  UNAUTHORIZED_ERROR,
} from './types';
import { getHeaders } from './AuthActions';
import { convertTimestamp, errorHandler } from '../helpers';
const ROOT_URL = SGM_SERVER_ADDRESS;

/**
 * Action Name: readFilteredMessages
 * Description:
 *      retrieve sgm for specific organization with masked phone number
 * Param: org_id, page_size, page_number
 * Return: array of filtered messages
 * Author: Gian
 * Last Update By: RJ
 */

export const readFilteredMessages = (PAYLOAD) => async (dispatch) => {
  let {
    organizationId,
    page,
    status,
    ordering = '-created_timestamp',
    created_timestamp__gte,
    created_timestamp__lte,

  } = PAYLOAD;
  try {
    let params = {
      page,
      ordering
    };
    if (status!==''){
      params['status'] = status;
    }
    if (created_timestamp__gte !== '' && created_timestamp__lte !== ''){
      params['created_timestamp__gte'] = created_timestamp__gte;
      params['created_timestamp__lte'] = created_timestamp__lte;

    }
    const headers = (await getHeaders()).headers;
    let config = {
      headers,
      params
    }
    let response;
    response = await axios.get(
      `${ROOT_URL}/organizations/${organizationId}/sgm/`,
      config
    );
      const data = response.data.results.map((item) => {
        return {
          ...item,
          id: item.id,
          status: item.status[0].toUpperCase() + item.status.substring(1).toLowerCase(),
          completedTimestamp: item.completedTimestamp && convertTimestamp(item.completedTimestamp),
          createdTimestamp:
            item.createdTimestamp && convertTimestamp(item.createdTimestamp),
        };
      });
      dispatch({
        type: SGM_FILTER_SUCCESS,
        payload: {
          sgmList: data,
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous
        },
      });
  } catch (e) {
    if (e.response.status === 401) {
      return {
        type: UNAUTHORIZED_ERROR,
        payload: {
          error: 'Unauthorized Access error',
          status: e.response.status,
        },
      };
    } else {
      dispatch({
        type: SGM_ERROR,
        payload: {
          error: "Can't retrieve SGM",
          description: e.response.error_message,
        },
      });
    }
  }
};

/**
 * Action Name: viewSpecificMessage
 * Description:
 *      retrieve single sgm
 * Param: organization_id, message_id
 * Return: object with completed_timestamp,
 *          created_timestamp,
 *          failure_reason,
 *          is_priority,
 *          message_id,
 *          organization_id,
 *          rate,
 *          retry_count,
 *          status,
 *          masked_mobile_number
 * Author: Gian
 * Last Update By: RJ
 */
export const viewSpecificMessage =
  (organization_id, message_id) => async (dispatch) => {
    try {
      const response = await axios.get(
        `${ROOT_URL}/organizations/${organization_id}/sgm/${message_id}/`,
        await getHeaders()
      );
        dispatch({
          type: SGM_READ_SUCCESS,
          payload: response.data,
        });
    } catch (e) {
      if (e.response === undefined || e.response.status === 401) {
        return dispatch(errorHandler('SGM', e));
      } else {
        dispatch({
          type: SGM_ERROR,
          payload: {
            error: "Can't view SGM",
            description: e.response.error_message,
          },
        });
      }
    }
  };
