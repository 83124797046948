/**
 * Component Name: SgmFilter
 * Description: Renders SGMFilter Component
 * Param: submitFilter,
          handleFromDateChange,
          handleToDateChange,
          selectedFromDate,
          selectedToDate,
          onStatusChange,
          statusValue,
          fieldError,
          fieldErrorMessage,
 * Return: Component
 * Author: RJ
 * Last Update By: RJ
 */

import { Button, FormHelperText, Grid, InputLabel } from '@material-ui/core';
import React from 'react';
import Select from 'react-select';
import MomentUtils from '@date-io/moment';

import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import { CommonStyles } from '../../assets/styles';
import { OrganizationSuggestStyles } from '../OrganizationSuggest/OrganizationSuggestStyles';

import { pp_blue_color } from '../../assets/styles/types';

const SgmFilter = ({
  submitFilter,
  handleFromDateChange,
  handleToDateChange,
  selectedFromDate,
  selectedToDate,
  onStatusChange,
  statusValue,
  fieldError,
  fieldErrorMessage,
}) => {
  const CONFIG = [
    { value: '', label: 'all' },
    { value: 'SUCCESS', label: 'success' },
    { value: 'FAILED', label: 'failed' },
  ];
  const commonClasses = CommonStyles();
  const classes = OrganizationSuggestStyles();
  return (
    <form onSubmit={submitFilter} id='filter-form'>
      <Grid container style={{ padding: '1rem 0' }}>
        <Grid
          item
          container
          style={{
            color: 'black',
          }}
        >
          <Grid
            container
            item
            md={4}
            alignContent='center'
            alignItems='center'
            style={{ margin: '1rem 0' }}
          >
            <Grid item style={{ padding: '0 1rem' }}>
              <InputLabel className={commonClasses.selectTitle}>
                From
              </InputLabel>
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                styles={{ color: '#fff' }}
              >
                <KeyboardDateTimePicker
                  id='date'
                  error={fieldError}
                  variant='inline'
                  inputVariant='outlined'
                  ampm={true}
                  format='LL'
                  onChange={handleFromDateChange}
                  value={selectedFromDate}
                />
              </MuiPickersUtilsProvider>
              <FormHelperText
                style={{ position: 'absolute' }}
                id='date-error-message'
                error={fieldError}
              >
                {fieldErrorMessage}
              </FormHelperText>
            </Grid>
          </Grid>
          <Grid
            container
            item
            md={4}
            alignContent='center'
            alignItems='center'
            style={{ margin: '1rem 0' }}
          >
            <Grid item style={{ padding: '0 1rem' }}>
              <InputLabel className={commonClasses.selectTitle}>To</InputLabel>
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                styles={{ color: '#fff' }}
              >
                <KeyboardDateTimePicker
                  error={fieldError}
                  variant='inline'
                  inputVariant='outlined'
                  ampm={true}
                  format='LL'
                  onChange={handleToDateChange}
                  value={selectedToDate}
                />
              </MuiPickersUtilsProvider>
              <FormHelperText
                style={{ position: 'absolute' }}
                id='date-error-message'
                error={fieldError}
              >
                {fieldErrorMessage}
              </FormHelperText>
            </Grid>
          </Grid>

          <Grid
            container
            item
            md={3}
            alignContent='center'
            alignItems='center'
            style={{ margin: '1rem 0' }}
          >
            <Grid
              item
              xs={10}
              style={{ padding: '0 1rem', maxWidth: '19.3rem' }}
            >
              <InputLabel className={commonClasses.selectTitle}>
                Status
              </InputLabel>
              <Select
                value={statusValue}
                options={CONFIG}
                onChange={onStatusChange}
                className={classes.custom}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: pp_blue_color,
                  },
                })}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            md={1}
            alignContent='center'
            alignItems='center'
            style={{ margin: '1rem 0' }}
          >
            <Grid item style={{ padding: '0 1rem', maxWidth: '19.3rem' }}>
              <Button
                variant='contained'
                color='primary'
                style={{ color: 'white', marginTop: '3rem' }}
                type='submit'
              >
                Apply
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export { SgmFilter };
