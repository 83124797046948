/**
 * Component Name: InvoiceTableView
 * Description: Displays the table view to dashboard page
 * Param: data, limit, handleDownload
 * Return: component
 * Author: Jeremiah
 * Last Update By: Jeremiah
 */
import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Button,
} from '@material-ui/core';
import { TableViewStyles, InvoiceTableViewStyles } from '../../assets/styles';
import { VIEW_INVOICES } from '../../helpers';
import { Loader } from '../Loader';
import { pp_blue_color_loader } from '../../assets/styles/types';

const InvoiceTableView = ({
  data,
  orgId,
  limit,
  handleDownload,
  permissions,
  viewLoader,
}) => {
  const classes = TableViewStyles();
  const invoiceClasses = InvoiceTableViewStyles();

  return (
    <div className={invoiceClasses.root} id="invoice-table-container">
      <p className={invoiceClasses.recentInvoices}>Recent Invoices</p>
      <Table className={classes.table} id="invoice-table-container">
        <TableHead className={classes.tableHead} id="invoice-table-head">
          <TableRow>
            <TableCell className={classes.tableCellHead}>Name</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {renderRow(
            data,
            orgId,
            limit,
            handleDownload,
            permissions,
            viewLoader
          )}
        </TableBody>
      </Table>
    </div>
  );
};

const renderRow = (
  data,
  orgId,
  limit,
  handleDownload,
  permissions,
  viewLoader
) => {
  const classes = TableViewStyles();
  const invoiceClasses = InvoiceTableViewStyles();
  return data.map((row, index) => (
    <TableRow key={index} className={classes.tableRow} id="invoice-table-cell">
      <TableCell
        id="file-name-table-cell"
        className={invoiceClasses.tableCellAllCaps}
      >
        INVOICE {row.reference} (<span style={{textTransform: 'capitalize'}}>{row.paymentMode}</span>)
      </TableCell>
      {permissions.includes(VIEW_INVOICES) ? (
        <TableCell
          className={invoiceClasses.downloadButtonCell}
          id="download-button-table-cell"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDownload(orgId, row.invoiceId, index)}
            className={invoiceClasses.downloadButton}
            disabled={viewLoader[index]}
          >
            {viewLoader[index] ? (
              <div className={classes.loader}>
                <Loader size={24} color={pp_blue_color_loader} />
              </div>
            ) : (
              'View'
            )}
          </Button>
        </TableCell>
      ) : null}
    </TableRow>
  ));
};

export { InvoiceTableView };
