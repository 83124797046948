import axios from 'axios';
import { Auth } from 'aws-amplify';
import { awsAuthConfig } from '../config';
import {
  AUTH_LOGIN_SUCCESS,
  AUTH_PASSWORD_REQUEST,
  AUTH_LOGIN_INVALID_CREDENTIALS,
  AUTH_CHANGE_PASS_SUCCESS,
  AUTH_CHANGE_PASS_FAILED,
  AUTH_FORCE_CHANGE_PASS_SUCCESS,
  AUTH_FORCE_CHANGE_PASS_FAILED,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_FAILED,
  AUTH_UPDATE,
  AUTH_EXPIRY,
  AUTH_REFRESH_TOKEN_SUCCESS,
  AUTH_FORGOT_PASSWORD_REQUEST,
  AUTH_FORGOT_PASSWORD_REQUEST_FAIL,
  AUTH_FORGOT_PASSWORD_SUCCESS,
  AUTH_FORGOT_PASSWORD_INVALID_CODE,
  AUTH_FORGOT_PASSWORD_EXPIRED_CODE,
  SESSION_EXPIRY_MESSAGE,
  USER_INFO_SUCCESS,
  ROOT_URL,
  ERROR_VERIFICATION_CODE,
  EXPIRED_VERIFICATION_CODE,
  INCORRECT_OLD_PASSWORD,
  ORGANIZATION_INACTIVE,
} from './types';
import { errorHandler } from '../helpers';
import { persistor, store } from '../store';

Auth.configure(awsAuthConfig);

export const getHeaders = async () => {
  const HEADERS = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + store.getState().auth.AccessToken,
    },
  };
  return HEADERS;
};

/**
 * Action Name: login
 * Description:
 *      checks user credentials and if successful it will direct it to DashboardPage
 * Param: payload
 * Return: ChallengeName,
 *          AccessToken,
 *          ExpiresIn,
 *          TokenType,
 *          RefreshToken,
 *          IdToken,
 *          permissions,
 *          organization_id,
 *          authStatus
 *          with data from the payload
 * Author: Jeremiah
 * Last Update By: RJ
 */
export const login =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      const user = await Auth.signIn(email, password);

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        dispatch({
          type: AUTH_PASSWORD_REQUEST,
          payload: user,
        });
      } else {
        const session = await Auth.currentSession();

        dispatch({
          type: AUTH_LOGIN_SUCCESS,
          payload: { user, session },
        });
      }
    } catch (error) {
      dispatch({
        type: AUTH_LOGIN_INVALID_CREDENTIALS,
        error: error,
      });
    }
  };

/**
 * Action Name: getUserInfo
 * Description:
 *      retrieve user's information
 * Author: RJ
 * Last Update By: RJ
 */

export const getUserInfo = () => async (dispatch) => {
  try {
    const authenticate = await axios.get(
      `${ROOT_URL}users-api/authenticate/`,
      await getHeaders()
    );
    if (authenticate.data.organization.active === false) {
      dispatch({
        type: ORGANIZATION_INACTIVE,
      });
    } else {
      const { data } = authenticate;

      const filteredCreatable = data.creatableRoles.map(
        ({ id, id: value, label, slug }) => ({
          id,
          value,
          label,
          slug,
        })
      );
      const filteredEditable = data.editableRoles.map(
        ({ id, id: value, label, slug }) => ({
          id,
          value,
          label,
          slug,
        })
      );

      dispatch({
        type: USER_INFO_SUCCESS,
        payload: { data, filteredCreatable, filteredEditable },
      });
    }
  } catch (e) {
    dispatch({
      type: AUTH_LOGIN_INVALID_CREDENTIALS,
      error: e,
    });
  }
};

/**
 * Action Name: forceChangePassword
 * Description:
 *      called on first login of user
 * Param: payload
 * Return: ChallengeName,
 *          AccessToken,
 *          ExpiresIn,
 *          TokenType,
 *          RefreshToken,
 *          IdToken,
 *          permissions,
 *          organization_id,
 *          authStatus
 * Author: Jeremiah
 * Last Update By: RJ
 */
export const forceChangePassword =
  ({ user, new_password }) =>
  async (dispatch) => {
    try {
      if (new_password.length > 98 || new_password.length < 6) {
        dispatch({
          type: AUTH_FORCE_CHANGE_PASS_FAILED,
          error:
            new_password.length > 98
              ? 'New password exceeded maximum length.'
              : 'Password not long enough.',
        });
      } else {
        const data = await Auth.completeNewPassword(user, new_password);
        dispatch({
          type: AUTH_FORCE_CHANGE_PASS_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      dispatch({
        type: AUTH_FORCE_CHANGE_PASS_FAILED,
        error: error,
      });
    }
  };

/**
 * Action Name: changePassword
 * Description:
 *      called on first login of user
 * Param: payload
 * Return: ChallengeName,
 *          AccessToken,
 *          ExpiresIn,
 *          TokenType,
 *          RefreshToken,
 *          IdToken,
 *          permissions,
 *          organization_id,
 *          authStatus
 * Author: Jeremiah
 * Last Update By: RJ
 */

export const changePassword =
  ({ password, new_password }) =>
  async (dispatch) => {
    try {
      if (new_password.length > 98 || new_password.length < 6) {
        dispatch({
          type: AUTH_CHANGE_PASS_FAILED,
          error:
            new_password.length > 98
              ? 'New password exceeded maximum length.'
              : 'Password not long enough.',
        });
      } else {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, password, new_password);

        dispatch({
          type: AUTH_CHANGE_PASS_SUCCESS,
        });
      }
    } catch (error) {
      if (error.code === 'NotAuthorizedException') {
        dispatch({
          type: INCORRECT_OLD_PASSWORD,
        });
      } else {
        dispatch({
          type: AUTH_CHANGE_PASS_FAILED,
          error: error.message,
        });
      }
    }
  };

/**
 * Action Name: logout
 * Description:
 *      logout user from the system
 * Param: void
 * Return: will empty ChallengeName,
 *          AccessToken,
 *          ExpiresIn,
 *          TokenType,
 *          RefreshToken,
 *          IdToken,
 *          permissions,
 *          organization_id,
 *          authStatus
 * Author: Jeremiah
 * Last Update By: RJ
 */
export const logout = (expired) => async (dispatch) => {
  if (expired) {
    dispatch({
      type: AUTH_EXPIRY,
      payload: {
        error: SESSION_EXPIRY_MESSAGE,
      },
    });
  } else {
    try {
      await Auth.signOut();
      dispatch({
        type: AUTH_LOGOUT_SUCCESS,
      });
      setTimeout(() => {
        persistor.purge();
      }, 1000);
    } catch (error) {
      dispatch({
        type: AUTH_LOGOUT_FAILED,
        payload: error,
      });
    }
  }
};

export const forgotPasswordRequest =
  ({ email }) =>
  async (dispatch) => {
    try {
      await Auth.forgotPassword(email);
      dispatch({
        type: AUTH_FORGOT_PASSWORD_REQUEST,
      });
    } catch (error) {
      dispatch({
        type: AUTH_FORGOT_PASSWORD_REQUEST,
      });
    }
  };

export const forgotPasswordConfirm =
  ({ code, email, new_password }) =>
  async (dispatch) => {
    try {
      await Auth.forgotPasswordSubmit(email, code, new_password);
      dispatch({
        type: AUTH_FORGOT_PASSWORD_SUCCESS,
      });
    } catch (error) {
      if (error.code === ERROR_VERIFICATION_CODE) {
        dispatch({
          type: AUTH_FORGOT_PASSWORD_INVALID_CODE,
          payload: {
            error: error.message,
          },
        });
      } else if (error.code === EXPIRED_VERIFICATION_CODE) {
        dispatch({
          type: AUTH_FORGOT_PASSWORD_EXPIRED_CODE,
          payload: {
            error: error.message,
          },
        });
      } else if (new_password.length > 98 || new_password.length < 6) {
        dispatch({
          type: AUTH_FORGOT_PASSWORD_REQUEST_FAIL,
          payload:
            new_password.length > 98
              ? 'New password exceeded maximum length.'
              : 'Password not long enough.',
        });
      } else {
        dispatch({
          type: AUTH_FORGOT_PASSWORD_REQUEST_FAIL,
          payload: error.message,
        });
      }
    }
  };

export const authRefreshToken = () => async (dispatch) => {
  try {
    const session = await Auth.currentSession();
    dispatch({
      type: AUTH_REFRESH_TOKEN_SUCCESS,
      payload: session,
    });
  } catch (e) {
    dispatch(errorHandler('Authentication', e, 'REFRESH_TOKEN'));
  }
};

/**
 * Action Name: formUpdate
 * Description:
 *      changes prop values
 * Param: prop, value
 * Return: sets action.payload.value to action.payload.prop
 * Author: Jeremiah
 * Last Update By: Shirwyn
 */
export const formUpdate = ({ prop, value }) => {
  return {
    type: AUTH_UPDATE,
    payload: {
      prop,
      value,
    },
  };
};
