import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import SgmViewStyles from './SgmViewStyles';
import moment from 'moment';

const SgmView = ({ maskedMobile, status, dateSent, dateCreated, rate, failure }) => {
    const classes = SgmViewStyles();
    return (
        <Grid container direction='column' className={classes.sideDrawer}>
            <Grid item xs>
                <Typography
                    className={classes.sideDrawerTitle}
                    variant='body1'
                    id='sgm-number'
                >
                    Number
                </Typography>
                <Typography
                    className={classes.sideDrawerData}
                    variant='body1'
                    id='sgm-number-data'
                >
                    {maskedMobile}
                </Typography>
            </Grid>
            <Grid item xs>
                <Typography
                    className={classes.sideDrawerTitle}
                    variant='body1'
                    id='sgm-status'
                >
                    Status
                </Typography>
                <Typography
                    className={classes.sideDrawerData}
                    variant='body1'
                    id='sgm-status-data'
                >
                    {status}
                </Typography>
            </Grid>
            <Grid item xs>
                <Typography
                    className={classes.sideDrawerTitle}
                    variant='body1'
                    id='sgm-datesent'
                >
                    Date Sent
                </Typography>
                <Typography
                    className={classes.sideDrawerData}
                    variant='body1'
                    id='sgm-datesent-data'
                >
                    {dateSent
                        ? moment(dateSent).format('llll')
                        : '---'
                    }

                </Typography>
            </Grid>
            <Grid item xs>
                <Typography
                    className={classes.sideDrawerTitle}
                    variant='body1'
                    id='sgm-datecreated'
                >
                    Date Created
                </Typography>
                <Typography
                    className={classes.sideDrawerData}
                    variant='body1'
                    id='sgm-datecreated-data'
                >
                    {moment(dateCreated).format('llll')}
                </Typography>
            </Grid>
            <Grid item xs>
                <Typography
                    className={classes.sideDrawerTitle}
                    variant='body1'
                    id='sgm-rate'
                >
                    Rate
                </Typography>
                <Typography
                    className={classes.sideDrawerData}
                    variant='body1'
                    id='sgm-rate-data'
                >
                    {rate}
                </Typography>
            </Grid>
            <Grid item xs>
                <Typography
                    className={classes.sideDrawerTitle}
                    variant='body1'
                    id='sgm-failure'
                >
                    Reason for Failure
                </Typography>
                <Typography
                    className={classes.sideDrawerData}
                    variant='body1'
                    id='sgm-failure-data'
                >
                    {failure}
                </Typography>
            </Grid>
        </Grid>
    );
}

export { SgmView }
