export const VIEW_CAMPAIGNS = 'VIEW_CAMPAIGNS_ALL';
export const CREATE_CAMPAIGNS = 'CREATE_CAMPAIGNS';
export const VIEW_INVOICES = 'VIEW_INVOICES_ALL';
export const VIEW_ORGANIZATIONS = 'VIEW_ORGANIZATIONS_ALL';
export const CREATE_ORGANIZATIONS = 'CREATE_ORGANIZATIONS';
export const UPDATE_ORGANIZATIONS = 'UPDATE_ORGANIZATIONS';
export const DELETE_ORGANIZATIONS = 'DELETE_ORGANIZATIONS';
export const VIEW_USERS = 'VIEW_USERS_ALL';
export const CREATE_USERS = 'CREATE_USERS';
export const UPDATE_USERS = 'UPDATE_USERS';
export const DELETE_USERS = 'DELETE_USERS';
export const DOWNLOAD_INVOICE = 'DOWNLOAD_INVOICE';
export const VIEW_SGM = 'VIEW_SGM_ALL';
