/**
 * Function Name: thisOrganizationTitle
 * Description: handles the title for the side drawer
 * Param: type, title
 * Return: Typography title
 * Author: Shirwyn
 * Last Update: Nelly
 */
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  Button,
  Typography,
  Grid,
  IconButton,
  Divider,
  Tooltip,
} from "@material-ui/core";
import {
  KeyboardBackspace,
  Create,
  WorkOff,
  Add,
  Work,
} from "@material-ui/icons";
import { OrganizationModal } from "../../components";
import TopUpModal from "../TopUpModal";
import { OrganizationSideDrawerStyles } from "./OrganizationSideDrawerStyles";
import { UPDATE_ORGANIZATIONS, DELETE_ORGANIZATIONS } from "../../helpers";

const thisOrganizationTitle = (type, title) => {
  switch (type) {
    case "view":
      return `View ${title}`;
    case "edit":
      return `Edit ${title}`;
    case "create":
      return `Create ${title}`;
    default:
      return null;
  }
};
/**
 * Class Name: OrganizationSideDrawer
 * Description: OrganizationSideDrawer component.
 * Param: N/A
 * Return: OrganizationSideDrawer Component
 * Author: Shirwyn
 * Last Update: Shirwyn
 */
const OrganizationSideDrawer = ({
  type,
  title,
  loadingModal,
  loaderColor,
  children,
  drawerState,
  onBack,
  onEdit,
  onDelete,
  showModal,
  handleCloseModal,
  getSelectedItem,
  deleteSelected,
  isActive,
  permissions,
  organizationName,
  orgModalInputChange,
  onActivate,
  displayTopUp,
    load,
  onClickTopUp
}) => {
  const classes = OrganizationSideDrawerStyles();
  const [amount, setAmount] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [hasError, setHasError] = useState(false);

  const onTopUp = (amount) => {
    if (amount === "" || amount < -load ) {
      setHasError(true);
    } else {
      // put top up logic here
      onClickTopUp(amount);
      setOpenModal(false);
    }
  };

  const handleTopUpAmount = (e) => {
    setAmount(e.target.value);
  };

  return (
    <Drawer anchor="right" open={drawerState} onClose={onBack}>
      <div className={classes.drawer} id="organization-side-drawer">
        <Button
          onClick={onBack}
          className={classes.button}
          id="organization-side-drawer-back-button"
        >
          <KeyboardBackspace />
          Back
        </Button>
        <Grid container className={classes.container}>
          <Grid item className={classes.gridItem}>
            <Typography variant="h5" gutterBottom className={classes.title}>
              {thisOrganizationTitle(type, title)}
            </Typography>
          </Grid>
          {type === "view" ? (
            <Grid item className={classes.gridItem}>
              {
                displayTopUp && (
                    <Tooltip title="Top Up">
                      <div>
                        <IconButton
                            onClick={() => setOpenModal(true)}
                            disabled={!isActive}
                            id="organization-side-drawer-top-up-button"
                        >
                          <Add />
                        </IconButton>
                        {openModal && (
                            <TopUpModal
                                isOpen={openModal}
                                isActive={isActive}
                                onClose={() => setOpenModal(false)}
                                onTopUp={onTopUp}
                                handleTopUpAmount={handleTopUpAmount}
                                topUpAmount={amount}
                                hasError={hasError}
                                selectedItem={getSelectedItem}
                                load={load}
                            />
                        )}
                      </div>
                    </Tooltip>
                )
              }
              {permissions.includes(UPDATE_ORGANIZATIONS) ? (
                <Tooltip title="Edit">
                  <span>
                    <IconButton
                      disabled={!isActive}
                      onClick={onEdit}
                      id="organization-side-drawer-edit-button"
                    >
                      <Create />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : null}
              {permissions.includes(DELETE_ORGANIZATIONS) ? (
                <Tooltip title={isActive ? "Disable" : "Enable"}>
                  <div>
                    {
                      <IconButton
                        onClick={onDelete}
                        id="organization-side-drawer-delete-button"
                      >
                        {isActive ? <WorkOff /> : <Work />}
                      </IconButton>
                    }
                  </div>
                </Tooltip>
              ) : null}
            </Grid>
          ) : null}
        </Grid>

        <OrganizationModal
          isOpen={showModal}
          activePage={title}
          closeModal={handleCloseModal}
          selectedItem={getSelectedItem}
          deleteItem={deleteSelected}
          isLoadingModal={loadingModal}
          loaderColor={loaderColor}
          orgName={organizationName}
          inputChange={orgModalInputChange}
          isActive={isActive}
          activateItem={onActivate}
        />
        <Divider className={classes.divider} />
        <div>{children}</div>
      </div>
    </Drawer>
  );
};
//Define OrganisationSideDrawer proptypes
OrganizationSideDrawer.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.object,
  drawerState: PropTypes.bool,
  onBack: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  getSelectedItem: PropTypes.string,
  deleteSelected: PropTypes.func,
  isActive: PropTypes.bool,
};

export { OrganizationSideDrawer, thisOrganizationTitle };
