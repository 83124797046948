/**
 * Function Name: errorHandler
 * Description: System error handler
 * Param: timestamp
 * Return: action
 * Author: Raymart
 * Last Update By: RJ
 */

import {
  ORGANIZATION_ERROR,
  USER_ERROR,
  SYSTEM_ERROR,
  USER_DELETE_ERROR,
  USER_NOT_FOUND,
  CAMPAIGN_ERROR,
  INVOICE_ERROR,
  INVOICE_READ_LIST_ERROR,
  GET_OUTSTANDING_BALANCE_ERROR,
  DASHBOARD_ERROR,
  AUTH_REFRESH_TOKEN_MISSING_REFRESH_TOKEN,
  AUTH_REFRESH_TOKEN_BLANK_REFRESH_TOKEN,
  AUTH_REFRESH_TOKEN_FAILED,
  UNAUTHORIZED_ERROR,
} from '../actions/types';

export const errorHandler = (module, e, action = null) => {
  if (e.message !== undefined && e.message.match(/Timeout/g)) {
    return {
      type: SYSTEM_ERROR,
      payload: {
        error: 'Request timeout',
        status: 408,
      },
    };
  }
  if (e.response === undefined) {
    return {
      type: SYSTEM_ERROR,
      payload: {
        error: 'Something went wrong',
        status: 403,
      },
    };
  }
  /**
   * MODULE: COMMON HANDLER
   * Description: Handles first layer of response
   */
  if (e.response.status === 401) {
    return {
      type: UNAUTHORIZED_ERROR,
      payload: {
        error: 'Unauthorized Access error',
        status: e.response.status,
      },
    };
  }
  if (e.response.status === 500) {
    return {
      type: SYSTEM_ERROR,
      payload: {
        error: 'Internal server error',
        status: e.response.status,
      },
    };
  }

  if (module === 'Dashboard') {
    return {
      type: DASHBOARD_ERROR,
      payload: {
        error: "Can't get count",
        description: e.response.error_message,
      },
    };
  } else if (module === 'Organization') {
    if (e.response.status === 422 || e.response.status === 400) {
      return {
        type: ORGANIZATION_ERROR,
        payload: {
          error: 'Organization already exists',
          status: e.response.status,
        },
      };
    } else {
      return {
        type: ORGANIZATION_ERROR,
        payload: {
          error: 'Invalid action',
          status: e.response.status,
        },
      };
    }
  } else if (module === 'User') {
    if (e.response.status === 422) {
      if (action !== 'DELETE_USER') {
        if (e.response.data.error_code === 'CANNOT_HOST_SUPER_ADMIN') {
          return {
            type: USER_ERROR,
            payload: {
              error: 'Organization cannot host super admin',
              status: e.response.status,
              error_code: e.response.data.error_code,
            },
          };
        } else if (e.response.data.error_code === 'ORGANIZATION_INACTIVE') {
          return {
            type: USER_ERROR,
            payload: {
              error: e.response.data.error_message,
              status: e.response.status,
              error_code: e.response.data.error_code,
            },
          };
        } else {
          return {
            type: USER_ERROR,
            payload: {
              error: 'User email exists',
              status: e.response.status,
              error_code: e.response.data.error_code,
            },
          };
        }
      } else {
        return {
          type: USER_ERROR,
          payload: {
            error:
              'Deleting own account or an already deleted user is not allowed',
            status: e.response.status,
          },
        };
      }
    } else if (e.response.status === 403) {
      if (action === 'UPDATE_USER') {
        return {
          type: USER_ERROR,
          payload: {
            error: 'Updating a user with the same role is not valid',
            status: e.response.status,
            error_code: 'INADEQUATE_PERMISSION',
          },
        };
      } else if (action === 'DELETE_USER') {
        return {
          type: USER_DELETE_ERROR,
          payload: {
            error: "You're not authorized to delete this user",
            status: e.response.status,
          },
        };
      } else if (action === 'VIEW_USER') {
        return {
          type: USER_ERROR,
          payload: {
            error: "Can't view single users",
            description: e.response.body,
          },
        };
      } else if (action === 'CREATE_USER') {
        return {
          type: USER_ERROR,
          payload: {
            error: "Can't create user",
            description: e.response.body,
            status: e.response.status,
          },
        };
      } else {
        return {
          type: USER_ERROR,
          payload: {
            error: "Can't retrieve users",
            description: e.response.body,
          },
        };
      }
    } else if (e.response.status === 404) {
      if (e.response.data.error_code === 'USER_DOES_NOT_EXIST') {
        return {
          type: USER_NOT_FOUND,
        };
      }
    } else {
      return {
        type: USER_ERROR,
        payload: {
          error: 'Invalid action',
          status: e.response.status,
        },
      };
    }
  } else if (module === 'Campaign') {
    if (action === 'VALIDATE_CAMPAIGN' && e.response.status === 422) {
      return {
        type: CAMPAIGN_ERROR,
        payload: {
          error: e.response.data.errorMessage,
          status: e.response.status,
          error_url: e.response.errorUrl,
        },
      };
    } else {
      return {
        type: CAMPAIGN_ERROR,
        payload: {
          error: e.response.data.errorMessage,
          status: e.response.status,
          error_url: e.response.errorUrl,
        },
      };
    }
  } else if (module === 'Invoice') {
    if (action === 'READ_INVOICES' || action === 'COMPUTE_INVOICE_UNBILLED') {
      return {
        type: INVOICE_ERROR,
        payload: {
          error: "Can't retrieve Invoices",
          description: e.response.error_message,
        },
      };
    } else if (
      action === 'READ_INVOICE_LIST' ||
      action === 'READ_LIMIT_LESS_INVOICE'
    ) {
      return {
        type: INVOICE_READ_LIST_ERROR,
        payload: {
          error: "Can't retrieve all invoice",
          description: e.response.error_message,
        },
      };
    } else if (action === 'COMPUTE_INVOICE_PRICE') {
      return {
        type: INVOICE_ERROR,
        payload: {
          error: 'Unable to compute Campaign Bill',
          description: e.response.error_message,
        },
      };
    } else if (action === 'VIEW_INVOICE') {
      return {
        type: INVOICE_ERROR,
        payload: {
          error: "Can't view invoice",
          description: e.response.error_message,
        },
      };
    } else if (action === 'GET_OUTSTANDING_BALANCE') {
      return {
        type: GET_OUTSTANDING_BALANCE_ERROR,
        payload: {
          error: "Can't retrieve outstanding balance",
          description: e.response.error_message,
        },
      };
    } else {
      return {
        type: INVOICE_ERROR,
        payload: {
          error: 'Invalid action',
          status: e.response.status,
        },
      };
    }
  } else if (action === 'REFRESH_TOKEN') {
    if (e.response.status === 400) {
      if (e.response.data.error_code === 'MISSING_REFRESH_TOKEN') {
        return {
          type: AUTH_REFRESH_TOKEN_MISSING_REFRESH_TOKEN,
          error: e.response.data.error_code,
        };
      } else {
        return {
          type: AUTH_REFRESH_TOKEN_BLANK_REFRESH_TOKEN,
          error: e.response.data.error_code,
        };
      }
    } else {
      return {
        type: AUTH_REFRESH_TOKEN_FAILED,
        error: e.response.data.error_code,
      };
    }
  } else {
    return {
      type: SYSTEM_ERROR,
      payload: {
        error: e.response.data.error_message,
        status: e.response.status,
      },
    };
  }
};
