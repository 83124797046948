import {
  DASHBOARD_READ_CAMPAIGN_SMS_COUNT_SUCCESS,
  DASHBOARD_READ_SGM_SMS_COUNT_SUCCESS,
  DASHBOARD_ERROR,
  SYSTEM_ERROR,
} from '../actions/types';

const INITIAL_STATE = {
  labels: [],
  campaignSentCount: [],
  campaignFailedCount: [],
  SGMSentCount: [],
  SGMFailedCount: [],
  error: '',
  status: '',
  system_error: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case DASHBOARD_READ_CAMPAIGN_SMS_COUNT_SUCCESS:
      return {
        ...state,
        labels: action.payload.map((report) => {
          return report.timeRange;
        }),
        campaignSentCount: action.payload.map((report) => {
          return report.successCount;
        }),
        campaignFailedCount: action.payload.map((report) => {
          return report.failedCount;
        }),
      };
    case DASHBOARD_READ_SGM_SMS_COUNT_SUCCESS:
      return {
        ...state,
        labels: action.payload.map((report) => {
          return report.timeRange;
        }),
        SGMSentCount: action.payload.map((report) => {
          return report.successCount;
        }),
        SGMFailedCount: action.payload.map((report) => {
          return report.failedCount;
        }),
      };
    case DASHBOARD_ERROR:
      return {
        ...state,
        error: action.payload.error,
        system_error: false,
      };
    case SYSTEM_ERROR:
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
        system_error: true,
      };
    default:
      return state;
  }
}
