import { createTheme } from '@material-ui/core/styles';

const CampaignFormOverrideStyles = createTheme({
  palette: {
    primary: {
      main: '#6cf',
    },
    secondary: {
      main: '#fff',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      input: {
        padding: '1rem !important',
        height: '1.5rem',
      },
    },
  },
});

export default CampaignFormOverrideStyles;
