/**
 * Component Name: OrganizationView
 * Description: Renders OrganizationView component
 * Param: N/A
 * Return: OrganizationView component
 * Author: Shirwyn
 * Last Update By: RJ
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import { convertTimestamp } from '../../helpers';
import { OrganizationViewStyles } from './OrganizationViewStyles';

const OrganizationView = ({
  org_name,
  aws_secret,
  created_by,
  date_created,
  active,
  campaignRate,
  sgmRate,
  paymentMode,
  load
}) => {
  const classes = OrganizationViewStyles();
  return (
    <Grid container direction='column' className={classes.container}>
      <Grid item xs className={classes.items}>
        <Typography
          variant='body1'
          className={`${classes.label} ${classes.labelTitle}`}
          id='org-name-label'
        >
          Organization Name
        </Typography>
        <Typography
          variant='body1'
          className={classes.label}
          id='org-name-data'
        >
          {org_name}
        </Typography>
      </Grid>
      <Grid item xs className={classes.items}>
        <Typography
            variant='body1'
            className={`${classes.label} ${classes.labelTitle}`}
            id='rate-label'
        >
          Payment Mode
        </Typography>
        <Typography variant='body1' className={classes.label} id='rate-data' style={{textTransform: 'capitalize'}}>
          {paymentMode}
        </Typography>
      </Grid>
      { paymentMode === "prepaid" &&
      <Grid item xs className={classes.items}>
        <Typography
            variant='body1'
            className={`${classes.label} ${classes.labelTitle}`}
            id='rate-label'
        >
          Load
        </Typography>
        <Typography variant='body1' className={classes.label} id='rate-data'>
          ₱ {load}
        </Typography>
      </Grid>
      }
      <Grid item xs className={classes.items}>
        <Typography
          variant='body1'
          className={`${classes.label} ${classes.labelTitle}`}
          id='rate-label'
        >
          Campaign Rate
        </Typography>
        <Typography variant='body1' className={classes.label} id='rate-data'>
          ₱ {campaignRate}
        </Typography>
      </Grid>
      <Grid item xs className={classes.items}>
        <Typography
          variant='body1'
          className={`${classes.label} ${classes.labelTitle}`}
          id='rate-label'
        >
          SGM Rate
        </Typography>
        <Typography variant='body1' className={classes.label} id='rate-data'>
          ₱ {sgmRate}
        </Typography>
      </Grid>
      <Grid item xs className={classes.items}>
        <Typography
          variant='body1'
          className={`${classes.label} ${classes.labelTitle}`}
          id='aws-secret-label'
        >
          AWS Secret
        </Typography>
        <Typography
          variant='body1'
          className={classes.label}
          id='aws-secret-data'
        >
          {aws_secret}
        </Typography>
      </Grid>
      <Grid item xs className={classes.items}>
        <Typography
          variant='body1'
          className={`${classes.label} ${classes.labelTitle}`}
          id='createdby-label'
        >
          Created By
        </Typography>
        <Typography
          variant='body1'
          className={classes.label}
          id='createdby-data'
        >
          {created_by}
        </Typography>
      </Grid>
      <Grid item xs className={classes.items}>
        <Typography
          variant='body1'
          className={`${classes.label} ${classes.labelTitle}`}
          id='date-created-label'
        >
          Date Created
        </Typography>
        <Typography
          variant='body1'
          className={classes.label}
          id='date-created-data'
        >
          {convertTimestamp(date_created)}
        </Typography>
      </Grid>
      <Grid item xs className={classes.items}>
        <Typography
          variant='body1'
          className={`${classes.label} ${classes.labelTitle}`}
          id='active-label'
        >
          Status
        </Typography>
        <Typography
          variant='body1'
          className={active === true ? classes.active : classes.inactive}
          id='active-data'
        >
          {active ? 'Active' : 'Inactive'}
        </Typography>
      </Grid>
    </Grid>
  );
};
//Define OrganizationView proptypes
OrganizationView.propTypes = {
  org_name: PropTypes.string,
  aws_secret: PropTypes.string,
  created_by: PropTypes.string,
  date_created: PropTypes.string,
  active: PropTypes.bool,
  rate: PropTypes.string,
};

export { OrganizationView };
