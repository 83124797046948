import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import {
  SgmSubHeader,
  Loader,
  SgmSideDrawer,
  SgmView,
  OrganizationSuggest,
  CommonTable,
  Pagination,
  SgmFilter,
} from '../../components';
import {
  readOrganizations,
  readFilteredMessages,
  viewSpecificMessage,
} from '../../actions';
import { connect } from 'react-redux';
import { OrgDropDownStyles } from '../../assets/styles';
import { SideDrawerLoaderStyles } from '../../assets/styles';
import { pp_blue_color_loader } from '../../assets/styles/types';
import { VIEW_SGM, VIEW_ORGANIZATIONS } from '../../helpers';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';

const limit = 10;

class SgmPage extends Component {
  _isMounted = false;
  _isTableInitialLoad = true;

  constructor(props) {
    super(props);
    this.state = {
      sgm: [],
      organizations: [],
      permissions: [],
      sgmInfo: {},
      filteredMessages: [],
      selectedOrgId: '',
      isLoadingTable: true,
      loaderColor: pp_blue_color_loader,
      onView: false,
      selectedMessageId: '',
      messageId: '',
      organizationId: '',
      disableViewSgm: false,
      selectedOrganization: null,
      isViewLoading: false,
      organization_names: [],
      sgmFilterOptions: [],
      selectedSgmFilter: {},
      currentPage: 1,
      fromPage: 0,
      toPage: 0,
      selectedFromDate: moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      }),
      selectedToDate: moment().set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 59,
      }),
      statusValue: { status: 'SUCCESS', label: 'success' },
      dateError: false,
      dateErrorMessage: '',
      startDate: '',
      endDate: '',
    };
    this.handleClicked = this.handleClicked.bind(this);
    this.closeViewDrawer = this.closeViewDrawer.bind(this);
    this.organizationSuggestHandler =
      this.organizationSuggestHandler.bind(this);
    this.sgmFilterHandler = this.sgmFilterHandler.bind(this);
    this.sgmPreviousPageHandler = this.sgmPreviousPageHandler.bind(this);
    this.handleDateTimeChange = this.handleDateTimeChange.bind(this);
    this.sgmNextPageHandler = this.sgmNextPageHandler.bind(this);
  }

  /**
   * Description: returns all the sgms of the first organization name in the dropdown
   * Param: selectedOrgId, organizations
   * Return: table view of all the sgms of the first organizations name in the dropdown
   * Author: Gian
   * Last Update By: Everard
   */
  componentDidMount() {
    const { activeOrg, inActiveOrg } = OrgDropDownStyles;
    this._isMounted = true;
    this._isTableInitialLoad = true;

    this.setState(
      {
        permissions: this.props.permissions,
        selectedOrgId: this.props.organizationId,
        sgmFilterOptions: [
          { label: 'Status', value: 'status' },
          { label: 'Date Sent', value: 'date_sent' },
          { label: 'Date Created', value: 'created_timestamp' },
        ],
        selectedSgmFilter: {
          label: 'Date Created',
          value: 'created_timestamp',
        },
      },
      () => {
        if (!this.state.permissions.includes(VIEW_SGM)) {
          if (this._isMounted) {
            this.setState({
              disableViewSgm: true,
            });
          }
        }

        if (!this.state.permissions.includes(VIEW_ORGANIZATIONS)) {
          this.props
            .readFilteredMessages({
              organizationId: this.state.selectedOrgId,
              page: this.state.currentPage,
              status: '',
            })
            .then(() => {
              if (this._isMounted) {
                this.setState(
                  {
                    filteredMessages: this.props.filteredMessages,
                    fromPage: this.props.totalCount === 0 ? 0 : 1,
                    toPage:
                      this.props.totalCount < limit
                        ? this.props.totalCount
                        : limit,
                  },
                  () => {
                    setTimeout(() => {
                      if (this._isMounted) {
                        this._isTableInitialLoad = false;
                        this.setState({ isLoadingTable: false });
                      }
                    }, 500);
                  }
                );
              }
            });
        } else {
          this.props.readOrganizations('', 500).then(() => {
            if (this._isMounted) {
              this.setState(
                {
                  organizations: this.props.organizations,
                  selectedOrgId: this.props.organizations[0].id,
                  selectedOrganization: {
                    value: this.props.organizations[0].id,
                    label: this.props.organizations[0].org_name,
                  },
                  organization_names: this.props.organizations.map((org) => {
                    let num = 17;
                    if (org.org_name.length >= num) {
                      return {
                        value: org.id,
                        status: org.active,
                        label: (
                          <>
                            {org.org_name.slice(0, num) + '...'}{' '}
                            {org.active ? (
                              <span style={activeOrg}>A</span>
                            ) : (
                              <span style={inActiveOrg}>IN</span>
                            )}
                          </>
                        ),
                      };
                    } else {
                      return {
                        value: org.id,
                        status: org.active,
                        label: (
                          <>
                            {org.org_name}
                            {org.active ? (
                              <span style={activeOrg}>A</span>
                            ) : (
                              <span style={inActiveOrg}>IN</span>
                            )}
                          </>
                        ),
                      };
                    }
                  }),
                },
                () => {
                  this.props
                    .readFilteredMessages({
                      organizationId: this.state.selectedOrgId,
                      page: this.state.currentPage,
                      status: '',
                    })
                    .then(() => {
                      if (this._isMounted) {
                        this.setState(
                          {
                            filteredMessages: this.props.filteredMessages,
                            selectedOrganization:
                              this.state.organization_names[0],
                            fromPage: this.props.totalCount === 0 ? 0 : 1,
                            toPage:
                              this.props.totalCount < limit
                                ? this.props.totalCount
                                : limit,
                          },
                          () => {
                            setTimeout(() => {
                              if (this._isMounted) {
                                this._isTableInitialLoad = false;
                                this.setState({ isLoadingTable: false });
                              }
                            }, 500);
                          }
                        );
                      }
                    });
                }
              );
            }
          });
        }
      }
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Component Name: handleDateTimeChange
   * Description: Handler for date/time picker
   * Param: dateTime
   * Return: void
   * Author: RJ
   * Last Update By: RJ
   */

  handleDateTimeChange(e) {
    const { name, value } = e;

    this.setState({
      [name]: value,
    });
  }

  /**
   * Function Name: sgmPreviousPageHandler
   * Description: handler for previous button of Pagination
   * Param: e
   * Return: void
   * Author: Shirwyn
   * Last Update By: RJ
   */
  sgmPreviousPageHandler(e) {
    e.preventDefault();

    if (this.state.currentPage > 1) {
      if (this._isMounted) {
        this.setState(
          {
            currentPage: this.state.currentPage - 1,
            isLoadingTable: true,
          },
          () => {
            let PAYLOAD = {
              organizationId: this.state.selectedOrgId,
              page: this.state.currentPage,
            };
            if (this.state.startDate !== '' && this.state.endDate !== '') {
              PAYLOAD = {
                organizationId: this.state.selectedOrgId,
                page: this.state.currentPage,
                status: this.state.statusValue.status,
                created_timestamp__gte: this.state.startDate,
                created_timestamp__lte: this.state.endDate,
              };
            }
            this.props
              .readFilteredMessages(PAYLOAD)
              .then(() => {
                if (this._isMounted) {
                  this.setState({
                    filteredMessages: this.props.filteredMessages,
                    isLoadingTable: false,
                  });
                }
              })
              .then(() => {
                this.setState({
                  fromPage: (this.state.currentPage - 1) * limit + 1,
                });
              })
              .then(() => {
                this.setState({
                  toPage:
                    this.state.filteredMessages.length +
                    this.state.fromPage -
                    1,
                });
              });
          }
        );
      }
    }
  }

  /**
   * Function Name: sgmNextPageHandler
   * Description: handler for next button of Pagination
   * Param: e
   * Return: void
   * Author: Shirwyn
   * Last Update By: RJ
   */
  sgmNextPageHandler(e) {
    e.preventDefault();
    if (this.props.hasNextPage) {
      if (this._isMounted) {
        this.setState(
          {
            isLoadingTable: true,
            currentPage: this.state.currentPage + 1,
          },
          () => {
            let PAYLOAD = {
              organizationId: this.state.selectedOrgId,
              page: this.state.currentPage,
            };
            if (this.state.startDate !== '' && this.state.endDate !== '') {
              PAYLOAD = {
                organizationId: this.state.selectedOrgId,
                page: this.state.currentPage,
                status: this.state.statusValue.status,
                created_timestamp__gte: this.state.startDate,
                created_timestamp__lte: this.state.endDate,
              };
            }
            this.props
              .readFilteredMessages(PAYLOAD)
              .then(() => {
                if (this._isMounted) {
                  this.setState({
                    filteredMessages: this.props.filteredMessages,
                    isLoadingTable: false,
                  });
                }
              })
              .then(() => {
                this.setState({
                  fromPage: (this.state.currentPage - 1) * limit + 1,
                });
              })
              .then(() => {
                if (this.state.toPage + limit > this.props.totalCount) {
                  this.setState({
                    toPage: this.props.totalCount,
                  });
                } else {
                  this.setState({
                    toPage: this.state.toPage + limit,
                  });
                }
              });
          }
        );
      }
    }
  }

  /**
   * Function Name: sgmFilterHandler
   * Description: handles the custom filtering of sgm table
   * Param: e
   * Return: void
   * Author: RJ
   * Last Update By: RJ
   */
  sgmFilterHandler(e) {
    e.preventDefault();

    let fromDate = moment(this.state.selectedFromDate)
      .set({
        seconds: 0, // gets the min seconds for accuracy
      })
      .toISOString(); // example returns 2021-11-22T16:00:00.000Z

    let toDate = moment(this.state.selectedToDate)
      .set({
        seconds: 59, // gets the max seconds for accuracy
      })
      .toISOString(); // example returns 2021-11-23T15:59:59.059Z

    if (
      moment(this.state.selectedFromDate).isSameOrBefore(
        this.state.selectedToDate
      )
    ) {
      if (this._isMounted) {
        this.setState(
          {
            isLoadingTable: true,
            currentPage: 1,
            startDate: fromDate,
            endDate: toDate,
          },
          () => {
            const PAYLOAD = {
              organizationId: this.state.selectedOrgId,
              page: this.state.currentPage,
              status: this.state.statusValue.status,
              created_timestamp__gte: fromDate,
              created_timestamp__lte: toDate,
            };
            this.props
              .readFilteredMessages(PAYLOAD)
              .then(() => {
                if (this._isMounted) {
                  this.setState(
                    {
                      filteredMessages: this.props.filteredMessages,
                    },
                    () => {
                      setTimeout(() => {
                        if (this._isMounted) {
                          this.setState({
                            isLoadingTable: false,
                          });
                        }
                      }, 500);
                    }
                  );
                }
              })
              .then(() => {
                this.setState({
                  fromPage: (this.state.currentPage - 1) * limit + 1,
                });
              })
              .then(() => {
                this.setState({
                  toPage:
                    this.props.filteredMessages.length +
                    this.state.fromPage -
                    1,
                });
              });
          }
        );
      }
    } else {
      this.setState({
        dateError: true,
        dateErrorMessage: 'Invalid Date Range',
      });
    }
  }

  /**
   * Function Name: organizationSuggestHandler
   * Description: handler for OrganizationSuggest select and search component
   * Param: selectedOrganization
   * Return: void
   * Author: Shirwyn
   * Last Update By: RJ
   */
  organizationSuggestHandler(selectedOrganization) {
    if (this._isMounted) {
      this.setState(
        {
          selectedOrganization: selectedOrganization,
          selectedOrgId: selectedOrganization.value,
          isLoadingTable: true,
          currentPage: 1,
        },
        () => {
          const PAYLOAD = {
            organizationId: this.state.selectedOrgId,
            page: this.state.currentPage,
            status: '',
          };
          this.props.readFilteredMessages(PAYLOAD).then(() => {
            if (this._isMounted) {
              this.setState(
                {
                  filteredMessages: this.props.filteredMessages,
                  fromPage: this.props.totalCount === 0 ? 0 : 1,
                  toPage:
                    this.props.totalCount < limit
                      ? this.props.totalCount
                      : limit,
                },
                () => {
                  setTimeout(() => {
                    if (this._isMounted) {
                      this.setState({
                        isLoadingTable: false,
                      });
                    }
                  }, 500);
                }
              );
            }
          });
        }
      );
    }
  }

  /**
   * Description: Opens the view component of the sgm component
   * Param: msgId
   * Return: void
   * Author: Gian
   * Last Update By: Everard
   */
  handleClicked(msgId) {
    if (this._isMounted) {
      this.setState(
        {
          onView: true,
          isViewLoading: true,
          selectedMessageId: msgId,
        },
        () => {
          this.props
            .viewSpecificMessage(
              this.state.selectedOrgId,
              this.state.selectedMessageId
            )
            .then(() => {
              if (this._isMounted) {
                this.setState(
                  {
                    organizationId: this.props.sgmData.organization_id,
                    messageId: this.props.sgmData.message_id,
                    sgmInfo: this.props.sgmData,
                  },
                  () => {
                    setTimeout(() => {
                      if (this._isMounted) {
                        this.setState({
                          isViewLoading: false,
                        });
                      }
                    }, 500);
                  }
                );
              }
            });
        }
      );
    }
  }

  /**
   * Description: Closes the view component of sgm component
   * Param: void
   * Return: void
   * Author: Gian
   * Last Update By: Everard
   */
  closeViewDrawer() {
    if (this._isMounted) {
      this.setState({
        onView: false,
      });
    }
  }
  render() {
    const { classes, sgmData } = this.props;
    return (
      <Grid>
        <SgmSubHeader
          id="sgm-header"
          title={'System Generated Messages (SGM)'}
          permissions={this.state.permissions}
          organizationSelect={
            <OrganizationSuggest
              id="org-Selected"
              organizationName={this.state.selectedOrganization}
              changeHandler={this.organizationSuggestHandler}
              organizations={this.state.organization_names}
              permissions={this.state.permissions}
            />
          }
        />
        <div>
          {!this._isTableInitialLoad && (
            <Grid container style={{ padding: '2rem 0' }} alignItems="center">
              <Grid item container lg={7}>
                <SgmFilter
                  fieldError={this.state.dateError}
                  fieldErrorMessage={this.state.dateErrorMessage}
                  submitFilter={this.sgmFilterHandler}
                  handleFromDateChange={(value) =>
                    this.setState({
                      selectedFromDate: value,
                      dateError: false,
                      dateErrorMessage: '',
                    })
                  }
                  handleToDateChange={(e) => {
                    this.setState({
                      selectedToDate: e,
                      dateError: false,
                      dateErrorMessage: '',
                    });
                  }}
                  onStatusChange={(e) =>
                    this.setState({
                      statusValue: {
                        status: e.value,
                        label: e.label,
                      },
                    })
                  }
                  selectedFromDate={this.state.selectedFromDate}
                  selectedToDate={this.state.selectedToDate}
                  statusValue={this.state.statusValue}
                />
              </Grid>
              <Grid item container lg={5}>
                <Pagination
                  id="sgm-page-active-filter"
                  customSelectID="sgm-active-filter"
                  filterList={this.state.sgmFilterOptions}
                  selectedFilter={this.state.selectedSgmFilter}
                  startingNumber={
                    this.props.totalCount === 0 ? 0 : this.state.fromPage
                  }
                  endingNumber={this.state.toPage}
                  totalCount={this.props.totalCount}
                  previousButtonHandler={(e) => this.sgmPreviousPageHandler(e)}
                  nextButtonHandler={(e) => this.sgmNextPageHandler(e)}
                  isPreviousButtonDisabled={this.state.currentPage <= 1}
                  isNextButtonDisabled={!this.props.hasNextPage}
                />
              </Grid>
            </Grid>
          )}

          {this.state.isLoadingTable ? (
            <Loader
              id="table-Loading"
              adjustSmall={window.screen.width <= 720}
              color={this.state.loaderColor}
            />
          ) : (
            <CommonTable
              id="sgm-table-view"
              headerArray={['Number', 'Status', 'Date Sent', 'Date Created']}
              arrangement={[
                'mobileNumber',
                'status',
                'completedTimestamp',
                'createdTimestamp',
              ]}
              colWidth={['25%', '25%', '25%', '25%']}
              data={this.state.filteredMessages}
              clickedRowHandler={this.handleClicked}
              page={'sgm'}
            />
          )}
        </div>
        {this.state.onView && this.state.disableViewSgm === false ? (
          <SgmSideDrawer
            id="sgm-drawer"
            open={this.state.onView}
            title={'SGM'}
            type={'view'}
            closeDrawer={this.closeViewDrawer}
          >
            {this.state.isViewLoading === true ? (
              <div className={classes.loader}>
                <Loader
                  id="sgm-view-loading"
                  size={300}
                  adjustSmall={window.screen.width <= 720}
                  color={this.state.loaderColor}
                />
              </div>
            ) : (
              <SgmView
                id="sgm-view"
                maskedMobile={sgmData.mobileNumber}
                status={
                  sgmData.status[0].toUpperCase() +
                  sgmData.status.substring(1).toLowerCase()
                }
                dateSent={sgmData.completedTimestamp}
                dateCreated={sgmData.createdTimestamp}
                rate={sgmData.smsRate}
                failure={
                  sgmData.failureReason === null
                    ? 'None'
                    : sgmData.failureReason
                }
              />
            )}
          </SgmSideDrawer>
        ) : null}
      </Grid>
    );
  }
}

const mapStatetoProps = ({ organization, sgm, auth }) => {
  const { organizations } = organization;
  const {
    filteredMessages,
    sgmData,
    totalCount,
    is_last_page,
    message_id,
    created_timestamp,
    hasNextPage,
  } = sgm;
  const { permissions, organizationId } = auth;
  return {
    organizations,
    filteredMessages,
    sgmData,
    totalCount,
    permissions,
    organizationId,
    is_last_page,
    message_id,
    created_timestamp,
    hasNextPage,
  };
};

export default compose(
  withStyles(SideDrawerLoaderStyles),
  connect(mapStatetoProps, {
    readOrganizations,
    readFilteredMessages,
    viewSpecificMessage,
  })
)(SgmPage);
