/**
 * Class Name: BillingPage
 * Description: Renders Billing View
 * Param: void
 * Return: View
 * Author: Jerimiah
 * Last Update By: RJ
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  readOrganizations,
  readInvoices,
  invoiceUnbilled,
  viewInvoice,
  readInvoiceList,
  getOutstandingBalance,
  viewOrganization,
} from '../../actions';
import { formatAmount, VIEW_INVOICES, VIEW_ORGANIZATIONS } from '../../helpers';
import { INVOICE_SERVER_ADDRESS } from '../../actions/types';
import { OrgDropDownStyles } from '../../assets/styles';

import {
  BillComponent,
  Loader,
  InvoiceTableView,
  BillingPageLayout,
  OrganizationSuggest,
  PageSnackBar,
  RefresherComponent, Pagination,
} from '../../components';
import { pp_blue_color_loader } from '../../assets/styles/types';

const pageSize = 12;

class BillingPage extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      organizations: [],
      invoices: [],
      selectedOrgId: '',
      previousBill: '',
      lastPayment: '',
      paymentDate: '',
      outstandingBalance: '',
      toPay: '',
      isLoadingTable: true,
      loaderColor: pp_blue_color_loader,
      billComponentIsLoading: true,
      selectedSort: '',
      permissions: [],
      root_url: INVOICE_SERVER_ADDRESS,
      selectedOrganization: null,
      organization_names: [],
      viewLoader: [],
      snackBarOpen: false,
      snackBarConfig: {
        status: '',
        message: '',
      },
      currentPage: 1,
      fromPage: 0,
      toPage: 0
    };
    this.onOrganizationChange = this.onOrganizationChange.bind(this);
    this.clickedInvoice = this.clickedInvoice.bind(this);
    this.nextPageHandler = this.nextPageHandler.bind(this);
    this.previousPageHandler = this.previousPageHandler.bind(this);
  }

  /**
   * Function Name: clickInvoice
   * Description: handler for handleDownload prop
   * Param: org_id, invoice_id
   * Return: void
   * Author: Jeremiah
   * Last Update By: Everard
   */
  clickedInvoice(org_id, invoice_id, index) {
    let list = this.state.viewLoader;
    list[index] = true;
    this.setState({ viewLoader: list });

    this.props.viewInvoice(org_id, invoice_id).then(() => {
      list[index] = false;
      setTimeout(() => {
        this.setState({ viewLoader: list });
      }, 500);

      const url = this.props.invoice_url;
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.click();
    });
  }

  componentDidMount() {
    this._isMounted = true;
    const { activeOrg, inActiveOrg } = OrgDropDownStyles;

    this.setState(
      {
        permissions: this.props.permissions,
      },
      () => {
        if (!this.props.permissions.includes(VIEW_ORGANIZATIONS)) {
          if (this._isMounted) {
            this.setState(
              {
                selectedOrgId: this.props.organizationId,
              },
              () => {
                this.props.viewOrganization(this.state.selectedOrgId);
                this.renderBillingComponent(this.state.selectedOrgId);
                this.renderInvoiceListComponent(this.state.selectedOrgId);
              }
            );
          }
        } else {
          this.props.readOrganizations('', 500).then(() => {
            if (this._isMounted) {
              this.setState(
                {
                  organizations: this.props.organizations,
                  selectedOrgId: this.props.organizations[0].id,
                  selectedOrganization: {
                    value: this.props.organizations[0].id,
                    label: this.props.organizations[0].org_name,
                  },
                  organization_names: this.props.organizations.map((org) => {
                    let num = 17;
                    if (org.org_name.length >= num) {
                      return {
                        value: org.id,
                        status: org.active,
                        label: (
                          <>
                            {org.org_name.slice(0, num) + '...'}{' '}
                            {org.active ? (
                              <span style={activeOrg}>A</span>
                            ) : (
                              <span style={inActiveOrg}>IN</span>
                            )}
                          </>
                        ),
                      };
                    } else {
                      return {
                        value: org.id,
                        status: org.active,
                        label: (
                          <>
                            {org.org_name}
                            {org.active ? (
                              <span style={activeOrg}>A</span>
                            ) : (
                              <span style={inActiveOrg}>IN</span>
                            )}
                          </>
                        ),
                      };
                    }
                  }),
                },
                () => {
                  this.setState({
                    selectedOrganization: this.state.organization_names[0],
                  });
                  this.renderBillingComponent(this.state.selectedOrgId);
                  this.renderInvoiceListComponent(this.state.selectedOrgId);
                }
              );
            }
          });
        }
      }
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Function Name: onOrganizationChange
   * Description: Changes the value of the input/select field
   * Param: selectedOrganization
   * Return: void
   * Author: Jeremiah
   * Last Update By: Everard
   */
  onOrganizationChange(selectedOrganization) {
    if (this._isMounted) {
      this.setState(
        {
          selectedOrganization: selectedOrganization,
          selectedOrgId: selectedOrganization.value,
          billComponentIsLoading: true,
          isLoadingTable: true,
          currentPage: 1
        },
        () => {
          this.props.viewOrganization(this.state.selectedOrgId);
          this.renderBillingComponent(this.state.selectedOrgId);
          this.renderInvoiceListComponent(this.state.selectedOrgId);
        }
      );
    }
  }

  /**
   * Function Name: renderBillingComponent
   * Description: Render the content of Billing component
   * Param: orgId
   * Return: void
   * Author: Everard
   * Last Update By: RJ
   */
  renderBillingComponent(orgId) {
    this.props.permissions.includes(VIEW_INVOICES) &&
      this.props.readInvoices(orgId, 1, 1 , true).then(() => {
        this.props.invoiceUnbilled(orgId).then(() => {
          this.props.getOutstandingBalance(orgId).then(() => {
            if (this._isMounted || !this.props.error) {
              this.setState(
                  {
                    previousBill: this.props.previousBill,
                    outstandingBalance: this.props.outstanding_balance,
                    toPay: this.props.unbilledTotal,
                  },
                  () => {
                    setTimeout(() => {
                      if (this._isMounted) {
                        this.setState({ billComponentIsLoading: false });
                      }
                    }, 500);
                  }
              );
            } else {
              this.setState({
                billComponentIsLoading: false,
              });
              this.handlePageSystemAlert('error', this.props.error);
            }
          });
        });
      });
  }

  /**
   * Function Name: renderInvoiceListComponent
   * Description: Render the invoice list table component
   * Param: orgId
   * Return: void
   * Author: Everard
   * Last Update By: Everard
   */
  renderInvoiceListComponent(orgId) {
    if (!this.props.error) {
      if (this._isMounted) {
        this.props.readInvoices(orgId, this.state.currentPage).then(() => {
          this.setState(
              {
                invoices: this.props.invoices,
                fromPage: this.props.totalCount === 0 ? 0 : 1,
                toPage:
                    this.props.totalCount < pageSize
                        ? this.props.totalCount
                        : pageSize,
              },
              () => {
                setTimeout(() => {
                  if (this._isMounted) {
                    this.setState({isLoadingTable: false});
                  }
                }, 500);
              }
          );
        })
      }
    } else {
      this.setState({
        isLoadingTable: false,
      });
      this.handlePageSystemAlert('error', this.props.error);
    }
  }

  /**
   * Function Name: handleAlertOncClose
   * Description: Triggers once the alert component requests to be closed according to the duration time
   * Param: handleAlertOnClose
   * Return: void
   * Author: Raymart
   * Last Update By: Raymart
   */

  handleAlertOnClose = () => {
    this.setState({
      snackBarOpen: false,
    });
  };

  /**
   * Function Name: handlePageSystemAlert
   * Description: Triggers once the alert is a system alert
   * Param: type, message
   * Return: void
   * Author: Raymart
   * Last Update By: Raymart
   */

  handlePageSystemAlert = (type, message) => {
    this.setState({
      snackBarOpen: true,
      snackBarConfig: {
        status: type,
        message: message,
      },
    });
  };

  /**
   * Function Name: onHandleBillRefresh
   * Description: handles the refresher for billing component
   * Param: none
   * Return: sets loader for billing to true and recalls renderBillingComponent()
   * Author: RJ
   * Last Update By: RJ
   */
  onHandleBillRefresh = () => {
    // starts loading
    this.setState({
      billComponentIsLoading: true,
    });
    // calls the api to re-render
    setTimeout(() => {
      this.renderBillingComponent(this.state.selectedOrgId);
      /**
       * The 10 Seconds interval was the minimum time needed ( in some cases ) to make another request from the Xero microservices
       * based on manual testing ).
       * In order for the interval to have a dynamic value, the backend should give the Retry-After HTTP header response and must handle it here.
       * for further explanation on handling the Xero Microservice Request Limitation.
       * https://developer.xero.com/documentation/guides/oauth2/limits/#uncertified-app-limits
       *  */
    }, [10000]);
  };

  /**
   * Function Name: onHandleInvoiceRefresh
   * Description: handles the refresher for invoice component
   * Param: none
   * Return: sets loader for invoice to true and recalls renderInvoiceListComponent()
   * Author: RJ
   * Last Update By: RJ
   */

  onHandleInvoiceRefresh = () => {
    // starts loading
    this.setState({
      isLoadingTable: true,
    });
    // calls the api to re-render
    setTimeout(() => {
      this.renderInvoiceListComponent(this.state.selectedOrgId);
      /**
       * The 10 Seconds interval was the minimum time needed ( in some cases ) to make another request from the Xero microservices
       * based on manual testing ).
       * In order for the interval to have a dynamic value, the backend should give the Retry-After HTTP header response and must handle it here.
       * for further explanation on handling the Xero Microservice Request Limitation.
       * https://developer.xero.com/documentation/guides/oauth2/limits/#uncertified-app-limits
       *  */
    }, [10000]);
  };

  /**
   * Function Name: previousPageHandler
   * Description: handler for next button of Pagination
   * Param: e
   * Return: void
   */
  previousPageHandler(e) {
    e.preventDefault();

    if (this.state.currentPage > 1) {
      if (this._isMounted) {
        this.setState(
            {
              currentPage: this.state.currentPage - 1,
              isLoadingTable: true,
            },
            () => {
              this.props
                  .readInvoices(
                      this.state.selectedOrgId,
                      this.state.currentPage,
                  )
                  .then(() => {
                    if (this._isMounted) {
                      this.setState({
                        invoices: this.props.invoices,
                        isLoadingTable: false,
                      });
                    }
                  })
                  .then(() => {
                    this.setState({
                      fromPage: (this.state.currentPage - 1) * pageSize + 1,
                    });
                  })
                  .then(() => {
                    this.setState({
                      toPage: this.state.invoices.length + this.state.fromPage - 1,
                    });
                  });
            }
        );
      }
    }
  }

  /**
   * Function Name: nextPageHandler
   * Description: handler for next button of Pagination
   * Param: e
   * Return: void
   */
  nextPageHandler(e) {
    e.preventDefault();

    if (this.props.hasNextPage) {
      if (this._isMounted) {
        //to Page
        if (this.state.toPage + pageSize > this.props.totalCount) {
          this.setState({
            toPage: this.props.totalCount,
          });
        } else {
          this.setState({
            toPage: this.state.toPage + pageSize,
          });
        }
        //from Page
        if (this.state.fromPage === this.props.totalCount) {
          this.setState({
            fromPage: this.props.totalCount,
          });
        } else {
          this.setState({
            fromPage: this.state.fromPage + pageSize,
          });
        }
        this.setState(
            {
              currentPage: this.state.currentPage + 1,
              isLoadingTable: true,
            },
            () => {
              this.props
                  .readInvoices(
                      this.state.selectedOrgId,
                      this.state.currentPage,
                  )
                  .then(() => {
                    if (this._isMounted) {
                      this.setState({
                        invoices: this.props.invoices,
                        isLoadingTable: false,
                      });
                    }
                  });
            }
        );
      }
    }
  }


  handleServerStatus = (status) => {
    const INTERNAL_SERVER_ERROR_STATUS = 500;
    return status === INTERNAL_SERVER_ERROR_STATUS;
  };

  render() {
    return (
      <>
        <BillingPageLayout
            paginationComponent={
              this.state.isLoadingTable ? null :
                this.props.totalCount >= 13 &&
                <div>
                  <Pagination
                      id="Pagination"
                      startingNumber={this.state.fromPage}
                      endingNumber={this.state.toPage}
                      totalCount={this.props.totalCount}
                      previousButtonHandler={(e) => this.previousPageHandler(e)}
                      nextButtonHandler={(e) => this.nextPageHandler(e)}
                      isPreviousButtonDisabled={this.state.currentPage <= 1}
                      isNextButtonDisabled={!this.props.hasNextPage}
                  />
                </div>
            }
          billComponent={
            this.state.billComponentIsLoading ? (
              <Loader
                id="bill-Loading"
                adjustSmall={true}
                size={250}
                color={this.state.loaderColor}
              />
            ) : (
              <RefresherComponent
                refresher={this.onHandleBillRefresh}
                component={() => (
                  <BillComponent
                    id="bill-Layout"
                    previousBill={formatAmount(this.state.previousBill)}
                    lastPayment={formatAmount(this.state.lastPayment)}
                    paymentDate={this.state.paymentDate}
                    outstandingBalance={formatAmount(
                      this.state.outstandingBalance
                    )}
                    toPay={formatAmount(this.state.toPay)}
                    paymentMode={this.props.organizationInfo.paymentMode}
                    load={this.props.organizationInfo.load}
                  />
                )}
                status={this.handleServerStatus(this.props.status.billStatus)}
              />
            )
          }
          invoiceComponent={
            this.state.isLoadingTable ? (
              <Loader
                id="invoice-Loading"
                size={300}
                adjustSmall={window.screen.width <= 520}
                color={this.state.loaderColor}
              />
            ) : (
              <RefresherComponent
                refresher={this.onHandleInvoiceRefresh}
                component={() => (
                  <InvoiceTableView
                    id="invoice-Layout"
                    data={this.props.invoices}
                    orgId={this.state.selectedOrgId}
                    handleDownload={this.clickedInvoice}
                    permissions={this.state.permissions}
                    viewLoader={this.state.viewLoader}
                  />
                )}
                status={this.handleServerStatus(
                  this.props.status.invoiceStatus
                )}
              />
            )
          }
          permissions={this.state.permissions}
          organizationSuggest={
            <OrganizationSuggest
              id="org-Selected"
              organizationName={this.state.selectedOrganization}
              changeHandler={this.onOrganizationChange}
              organizations={this.state.organization_names}
              permissions={this.state.permissions}
            />
          }
        />
        <PageSnackBar
          id="message-Handler"
          open={this.state.snackBarOpen}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={6000}
          onClose={this.handleAlertOnClose}
          snackBarConfig={this.state.snackBarConfig}
        />
      </>
    );
  }
}

const mapStatetoProps = ({ auth, organization, report, invoice }) => {
  const { AccessToken, permissions, organizationId } = auth;
  const { organizations, organizationInfo } = organization;
  const { labels, sentCount, failedCount } = report;
  const {
    invoiceInfo,
    unbilledTotal,
    invoices,
    invoice_url,
    outstanding_balance,
    system_error,
    error,
    status,
    previousBill,
    hasNextPage,
    totalCount
  } = invoice;
  return {
    AccessToken,
    permissions,
    organizationId,
    organizations,
    labels,
    sentCount,
    failedCount,
    invoiceInfo,
    unbilledTotal,
    invoices,
    invoice_url,
    outstanding_balance,
    system_error,
    error,
    status,
    previousBill,
    hasNextPage,
    totalCount,
    organizationInfo
  };
};

export default connect(mapStatetoProps, {
  readOrganizations,
  viewOrganization,
  readInvoices,
  invoiceUnbilled,
  viewInvoice,
  readInvoiceList,
  getOutstandingBalance,
})(BillingPage);
