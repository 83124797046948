export const config = {
  // API URLs
  SMS_API_URL: process.env.REACT_APP_API_URL,
  SMS_AIRBRAKE_PROJECT_ID: process.env.REACT_APP_SMS_AIRBRAKE_PROJECT_ID,
  SMS_AIRBRAKE_PROJECT_KEY: process.env.REACT_APP_SMS_AIRBRAKE_PROJECT_KEY,
};
export const awsAuthConfig = {
  region: process.env.REACT_APP_AUTH_REGION,
  userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
};
